import { Card, Col, Row, Image, Button, Skeleton } from 'antd';
import styles from './index.module.css';
import APIKeyModal from '../../components/global/APIKeyModal';
import { useEffect, useState } from 'react';
import connectionService from '../../client-lib/api/connection-service';
import { toastText } from '../../components/global/toast';
import WooCommerceAuthModal from '../../components/global/wooCommerceAuthModal';
import { DisconnectModal } from './Component';
import LinnworksModal from '../../components/global/LinnworksModal';
import ShopifyModal from '../../components/global/ShopifyModal';

export const Integrations = () => {
    const [connectionData, setConnectionData] = useState<any>(null);

    const [isAPIKeyModalOpen, setIsAPIKeyModalOpen] = useState(false);
    const [isWooAuthModalOpen, setIsWooAuthModalOpen] = useState(false);

    const [isXeroConnectionLoading, setIsXeroConnectionLoading] =
        useState(false);

    const [isDisconnectModalOpen, setIsDisconnectModalOpen] = useState(false);

    const [isLinnworksModalOpen, setIsLinnworksModalOpen] = useState(false);

    const [isShopifyModalOpen, setIsShopifyModalOpen] = useState(false);

    const [isStripeConnectionLoading, setIsStripeConnectionLoading] =
        useState(false);

    const [connectionName, setConnectionName] = useState<String | null>(null);

    useEffect(() => {
        getConnections();
    }, []);

    const myCards = [
        {
            id: 'hubspot',
            name: 'HubSpot',
            key: 1,
            isConnected: connectionData?.hubSpotCredentials,
            buttonText: connectionData?.hubSpotCredentials
                ? 'Disconnect'
                : 'Connect to HubSpot',
            logo: '../hubspot.png',
            ghost: false,
            color: '#fff',
            backgroundColor: '#f77c56',
            width: '180px',
            onClick: () => {
                setConnectionName('hubSpot');
                connectionData?.hubSpotCredentials
                    ? handleDisconnect()
                    : hubSpotAuth();
            },
            companyName: connectionData?.hubspotCompanyName,
        },
        {
            id: 'linnworks',
            name: 'Linnworks',
            key: 2,
            isConnected: connectionData?.linnworksCredentials,
            buttonText: connectionData?.linnworksCredentials
                ? 'Disconnect'
                : 'Connect to Linnworks',
            logo: '../linnworks-logo.png',
            ghost: false,
            color: '#fff',
            backgroundColor: '#222B36',
            width: '250px',
            onClick: () => {
                setConnectionName('linnworks');
                connectionData?.linnworksCredentials
                    ? handleDisconnect()
                    : handleLinnWorks();
            },
            companyName: connectionData?.linnworksCompanyName,
        },
        {
            id: 'xero',
            name: 'Xero',
            key: 3,
            isConnected: connectionData?.xeroCredentials,
            buttonText: connectionData?.xeroCredentials
                ? 'Disconnect'
                : 'Connect to Xero',
            logo: '../xero-logo.png',
            ghost: false,
            color: '#fff',
            width: '100px',
            backgroundColor: '#00B2D5',
            isLoading: isXeroConnectionLoading,
            onClick: () => {
                setConnectionName('xero');
                connectionData?.xeroCredentials
                    ? handleDisconnect()
                    : xeroAuth();
            },
            companyName: connectionData?.xeroCompanyName,
        },
        {
            id: 'wooCommerce',
            name: 'Woo Commerce',
            key: 4,
            isConnected: connectionData?.wooCommerceCredentials,
            buttonText: connectionData?.wooCommerceCredentials
                ? 'Disconnect'
                : 'Connect to Woo Commerce',
            logo: '../wooCommerce-logo.png',
            ghost: false,
            color: '#fff',
            width: '220px',
            backgroundColor: '#945687',
            onClick: () => {
                setConnectionName('woocommerce');
                connectionData?.wooCommerceCredentials
                    ? handleDisconnect()
                    : wooCommerceAuth();
            },
            companyName: connectionData?.wooCommerceCompanyName,
        },
        {
            id: 'shopify',
            name: 'Shopify',
            key: 5,
            isConnected: connectionData?.shopifyCredentials,
            buttonText: connectionData?.shopifyCredentials
                ? 'Disconnect'
                : 'Connect to Shopify',
            logo: '../shopify-logo.png',
            ghost: false,
            color: '#fff',
            backgroundColor: '#5A863E',
            onClick: () => {
                setConnectionName('shopify');
                connectionData?.shopifyCredentials
                    ? handleDisconnect()
                    : handleShopify();
            },
            companyName: connectionData?.shopifyCompanyName,
        },
        {
            id: 'stripe',
            name: 'Stripe',
            key: 6,
            isConnected: connectionData?.stripeCredentials,
            buttonText: connectionData?.stripeCredentials
                ? 'Disconnect'
                : 'Connect to Stripe',
            logo: '../stripe.png',
            ghost: false,
            color: '#fff',
            backgroundColor: '#635bff',
            isLoading: isStripeConnectionLoading,
            onClick: () => {
                setConnectionName('stripe');
                connectionData?.stripeCredentials
                    ? handleDisconnect()
                    : shopifyAuth();
            },
            companyName: connectionData?.stripeCompanyName,
        },
    ];

    const handleDisconnect = async () => {
        setIsDisconnectModalOpen(true);
    };

    const handleLinnWorks = async () => {
        setIsLinnworksModalOpen(true);
    };

    const handleShopify = async () => {
        setIsShopifyModalOpen(true);
    };

    const getConnections = async () => {
        try {
            const res = await connectionService.getConnection();

            if (res?.result) {
                setConnectionData(res.result);
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        }
    };

    const hubSpotAuth = async () => {
        setIsAPIKeyModalOpen(true);
    };

    const wooCommerceAuth = async () => {
        setIsWooAuthModalOpen(true);
    };

    const xeroAuth = async () => {
        try {
            setIsXeroConnectionLoading(true);

            const response = await connectionService.xeroAuth();
            if (response.result) {
                window.open(response.result.authUrl, '_self');
            } else {
                toastText('Something went wrong, please try again.', 'error');
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        } finally {
            setIsXeroConnectionLoading(false);
        }
    };

    const shopifyAuth = async () => {
        try {
            setIsStripeConnectionLoading(true);

            const response = await connectionService.stripeAuth();
            if (response.result) {
                window.open(response.result.authUrl, '_self');
            } else {
                toastText('Something went wrong, please try again.', 'error');
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        } finally {
            setIsStripeConnectionLoading(false);
        }
    };
    return (
        <>
            <Row gutter={16} className={styles['mr-5']}>
                {connectionData
                    ? myCards.map((card: any, index: any) => (
                          <Col key={card.key} span={8}>
                              <Card
                                  className={styles['integration-card']}
                                  key={index}
                                  hoverable={true}
                                  id={card?.id}
                                  title={
                                      <div
                                          className={
                                              styles['integration-card-header']
                                          }
                                      >
                                          <Image
                                              src={card.logo}
                                              style={{
                                                  height: '50px',
                                                  marginRight: '10px',
                                              }}
                                              width={card.width}
                                              preview={false}
                                          />
                                      </div>
                                  }
                              >
                                  <div className={styles['company-text']}>
                                      <p>
                                          {card.isConnected
                                              ? 'Connected'
                                              : 'Connect'}{' '}
                                          with :
                                      </p>
                                      <p>
                                          {card.isConnected
                                              ? card.companyName || '-'
                                              : card.name}
                                      </p>
                                  </div>
                                  <div className="d-flex justify-content-end">
                                      <Button
                                          ghost={card?.ghost}
                                          onClick={card?.onClick}
                                          style={{
                                              backgroundColor:
                                                  card.backgroundColor,
                                              color: card.color,
                                          }}
                                          className={card?.disabled ? '' : ''}
                                          loading={card?.isLoading}
                                          disabled={card?.disabled}
                                      >
                                          {card?.buttonText}
                                      </Button>
                                  </div>
                              </Card>
                          </Col>
                      ))
                    : myCards.map((_, index) => (
                          <Col key={index} span={8}>
                              <Skeleton active />
                          </Col>
                      ))}
            </Row>
            {/* HubSpot API key modal */}
            {isAPIKeyModalOpen ? (
                <APIKeyModal
                    keyModalVisible={isAPIKeyModalOpen}
                    onClose={() => {
                        setIsAPIKeyModalOpen(false);
                        getConnections();
                    }}
                />
            ) : null}
            {/* WooCommerce fields modal */}
            {isWooAuthModalOpen ? (
                <WooCommerceAuthModal
                    authModalVisible={isWooAuthModalOpen}
                    onClose={() => {
                        setIsWooAuthModalOpen(false);
                        getConnections();
                    }}
                />
            ) : null}

            {isDisconnectModalOpen && (
                <DisconnectModal
                    open={isDisconnectModalOpen}
                    onCancel={() => {
                        setIsDisconnectModalOpen(false);
                        setConnectionName(null);
                        getConnections();
                    }}
                    moduleName={connectionName}
                />
            )}

            {isLinnworksModalOpen && (
                <LinnworksModal
                    open={isLinnworksModalOpen}
                    onClose={() => {
                        setIsLinnworksModalOpen(false);
                        getConnections();
                    }}
                />
            )}

            {isShopifyModalOpen && (
                <ShopifyModal
                    open={isShopifyModalOpen}
                    onClose={() => {
                        setIsShopifyModalOpen(false);
                        getConnections();
                    }}
                />
            )}
        </>
    );
};
