import React, { useEffect, useState } from 'react';
import { Button, Col, notification, Row, Select, Table } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { PaymentGatewayType } from './types';
import configurationService from '../../../client-lib/api/configuration-service';
import { IPagination, IQuery } from '../../../lib/types/global-types';
import { toastText } from '../../../components/global/toast';
import toast from 'react-hot-toast';
import { WarningOutlined } from '@ant-design/icons';
import { openNotification } from '../../../components/global/notificationHelper';

const PaymentGateway = () => {
    const [loading, setLoading] = useState(false);
    const [selectLoading, setSelectLoading] = useState(false);
    const [paymentGatewayData, setPaymentGatewayData] = useState<PaymentGatewayType[]>([]);
    const [updatedPaymentGatewaysData, setUpdatedPaymentGatewaysData] = useState<PaymentGatewayType[]>([]);
    const [xeroAccountOptions, setXeroAccountOptions] = useState<any>([]);
    const [pagination, setPagination] = useState<IPagination>({
        pageNo: 1,
        pageSize: 10,
    });

    const fetchPaymentGateways = async () => {
        setLoading(true);
        try {
            const query: IQuery = {
                ...pagination,
            };
            const paymentGatewaysResponse = await configurationService.getPaymentGateways(query);
            const paymentGateways = paymentGatewaysResponse.result?.content;
            setPaymentGatewayData(paymentGateways);
            setLoading(false);
        } catch (error: any) {
            const errorMessage = error.errorDescription || error.message;
            toastText(errorMessage, 'error');
            setLoading(false);
        }
    };

    async function getXeroAccountList() {
        try {
            setSelectLoading(true);
            let xeroAccountList: any = await configurationService.getXeroAccount();
            const xeroAccounts = xeroAccountList.result.content;
            const formattedOptions = xeroAccounts.map((account: any) => ({
                label: account.name,
                value: account.code,
            }));
            setXeroAccountOptions(formattedOptions);
            setSelectLoading(false);
            return xeroAccountList;
        } catch (error) {
            console.error('Error fetching Xero account list:', error);
            setSelectLoading(false);
            return [];
        }
    }

    useEffect(() => {
        fetchPaymentGateways();
        getXeroAccountList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination]);

    const xeroAccounts = [
        { value: 'stripeClearanceAccount', label: 'Stripe Clearance Account' },
        { value: 'option2', label: 'Option 2' },
    ];

    const handleSelectChange = (value: string, id: string, field: keyof PaymentGatewayType) => {
        setPaymentGatewayData((prevData: any) => {
            const updatedData = prevData.map((paymentGateway: any) => {
                if (paymentGateway.id === id) {
                    const updatedPaymentGatewayData = { ...paymentGateway, [field]: value };
                    setUpdatedPaymentGatewaysData((prevUpdatedData: any) => {
                        const updated = prevUpdatedData.filter((p: any) => p.id !== id);
                        return [...updated, updatedPaymentGatewayData];
                    });
                    return updatedPaymentGatewayData;
                }
                return paymentGateway;
            });
            return updatedData;
        });
    };

    const hasPartialUpdate = (originalData: PaymentGatewayType[], updatedData: PaymentGatewayType[]) => {
        const updatedMap = new Map(updatedData.map((item) => [item.id, item]));

        for (const original of originalData) {
            const updated = updatedMap.get(original.id);

            if (!updated) continue;
            let hasUpdates = false;
            let hasAllFieldsUpdated = true;

            for (const key in original) {
                if (original.hasOwnProperty(key)) {
                    if (original[key as keyof PaymentGatewayType] !== updated[key as keyof PaymentGatewayType]) {
                        hasUpdates = true;
                    }
                    if (updated[key as keyof PaymentGatewayType] === undefined || updated[key as keyof PaymentGatewayType] === '') {
                        hasAllFieldsUpdated = false;
                    }
                }
            }
            if (hasUpdates && !hasAllFieldsUpdated) {
                return true;
            }
        }
        return false;
    };

    const handleSaveButton = async () => {
        setLoading(true);
        if (!updatedPaymentGatewaysData || updatedPaymentGatewaysData.length === 0) {
            openNotification('warning', 'Warning', 'No changes have been made to the payment gateway settings')
            // toastText('No changes have been made to the payment gateway settings.', 'warning');
            setLoading(false);
        } else if (hasPartialUpdate(paymentGatewayData, updatedPaymentGatewaysData)) {
            toast.error('Please complete all fields in the row before saving.');
            setLoading(false);
        } else {
            try {
                const response = await configurationService.updatePaymentGateways(updatedPaymentGatewaysData);
                if (response.result?.success) {
                    toastText('Payment Gateway updated successfully', 'success');
                }
                setLoading(false);
                setUpdatedPaymentGatewaysData([]);
            } catch (error: any) {
                let errorMessage = error.message;

                if (error.errorDescription) {
                    errorMessage = error.errorDescription;
                }
                toastText(errorMessage, 'error');
            }
            setLoading(false);
        }
    };

    const columns: TableColumnsType<PaymentGatewayType> = [
        {
            title: 'Payment Gateway',
            dataIndex: 'name',
            key: 'name',
            width: '33%',
        },
        {
            title: 'Select COA',
            dataIndex: 'coaAccount',
            key: 'coaAccount',
            width: '33%',
            render: (text, record: any) => (
                <Select
                    loading={selectLoading}
                    style={{ width: '50%' }}
                    showSearch
                    onChange={(value, option: any) => {
                        const label = option.label
                        handleSelectChange(value, String(record.id), 'coaCode');
                        handleSelectChange(label, String(record.id), 'coaAccount');
                    }}
                    placeholder={record.paymentGateway ? `Select ${record.paymentGateway} COA Account` : 'Select COA Account'}
                    filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                    value={record.coaAccount || undefined}
                    options={xeroAccountOptions}
                />
            ),
        },
        {
            title: 'Fees Account',
            dataIndex: 'feesAccount',
            key: 'feesAccount',
            width: '33%',
            render: (text, record: any) => (
                <Select
                    loading={selectLoading}
                    style={{ width: '50%' }}
                    showSearch
                    onChange={(value, option: any) => {
                        const label = option.label
                        handleSelectChange(value, String(record.id), 'feesCode');
                        handleSelectChange(label, String(record.id), 'feesAccount');
                    }}
                    placeholder={record.paymentGateway ? `Select ${record.paymentGateway} Fees Account` : 'Select Fees Account'}
                    filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                    value={record.feesAccount || undefined}
                    options={xeroAccountOptions}
                />
            ),
        },
    ];

    const PaymentGatewayData: PaymentGatewayType[] = [
        {
            id: '1',
            paymentGateway: 'Stripe',
        },
        {
            id: '2',
            paymentGateway: 'Klarna',
        },
        {
            id: '3',
            paymentGateway: 'Clearpay',
        },
        {
            id: '4',
            paymentGateway: 'Paypal',
        },
    ];
    return (
        <>
            <Row gutter={24}>
                <Col span={24} style={{ marginBottom: '10px' }}>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <Button type="primary" size="large" onClick={handleSaveButton}>
                            Save
                        </Button>
                    </div>
                </Col>
                <Col
                    span={24}
                    style={{
                        height: 'calc(100vh - 249.48px)',
                    }}
                >
                    <Table loading={loading} columns={columns} dataSource={paymentGatewayData} rowKey={record => record.id} />
                </Col>
            </Row>
        </>
    );
};

export default PaymentGateway;
