import http from '../http';

const BASE_PATH = process.env.REACT_APP_API_ENDPOINT;

const getProducts = async (query: any) => {
    return http.get(`${BASE_PATH}/v1/dashboard/product`, {
        queryParams: query,
    });
};

const getSales = async () => {
    return http.get(`${BASE_PATH}/v1/dashboard/sales`);
};

const getSalesCount = async (query: any) => {
    return http.get(`${BASE_PATH}/v1/dashboard/salesCounts`, {
        queryParams: query,
    });
};

const getOrderCountsAndPercentages = async (query: any) => {
    return http.get(`${BASE_PATH}/v1/dashboard/orderCounts`, {
        queryParams: query,
    });
};

const getShopifySales = async () => {
    return http.get(`${BASE_PATH}/v1/dashboard/shopifySales`);
};

const dashboardService = {
    getProducts,
    getSales,
    getSalesCount,
    getOrderCountsAndPercentages,
    getShopifySales,
};

export default dashboardService;
