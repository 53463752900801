export const ToastSuccessSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={21} fill="none">
            <path
                fill="#fff"
                d="m8.542 14.723 6.98-6.98-1.384-1.371-5.596 5.596L5.77 9.195l-1.358 1.372 4.131 4.156Zm1.453 5.397c-1.375 0-2.67-.26-3.885-.782a10.066 10.066 0 0 1-3.185-2.144 10.073 10.073 0 0 1-2.143-3.182A9.739 9.739 0 0 1 0 10.128c0-1.39.26-2.694.782-3.909a9.95 9.95 0 0 1 2.141-3.173c.907-.9 1.967-1.613 3.182-2.139A9.684 9.684 0 0 1 9.99.12c1.392 0 2.696.262 3.912.786a10.096 10.096 0 0 1 3.174 2.136c.9.899 1.612 1.956 2.137 3.172A9.764 9.764 0 0 1 20 10.126a9.691 9.691 0 0 1-.788 3.887 10.23 10.23 0 0 1-2.138 3.183 9.951 9.951 0 0 1-3.172 2.142 9.798 9.798 0 0 1-3.907.782Zm-.002-1.912c2.248 0 4.157-.786 5.727-2.358 1.57-1.572 2.355-3.48 2.355-5.724 0-2.248-.785-4.156-2.355-5.726-1.57-1.57-3.481-2.355-5.734-2.355-2.236 0-4.141.785-5.714 2.355-1.574 1.57-2.36 3.48-2.36 5.733 0 2.237.786 4.142 2.358 5.715 1.572 1.573 3.48 2.36 5.723 2.36Z"
            />
        </svg>
    );
};

export const ToastErrorSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={21} fill="none">
            <path
                d="M7.12531 15.1784L10.6358 11.668L14.1462 15.1784L15.2688 14.0558L11.7584 10.5454L15.2688 7.03494L14.1462 5.91234L10.6358 9.42279L7.12531 5.91234L6.00271 7.03494L9.51316 10.5454L6.00271 14.0558L7.12531 15.1784ZM10.6376 20.5386C9.2627 20.5386 7.96765 20.278 6.75249 19.7569C5.53734 19.2357 4.4758 18.5212 3.56787 17.6133C2.65995 16.7054 1.94542 15.6444 1.42429 14.4305C0.903147 13.2166 0.642578 11.9222 0.642578 10.5473C0.642578 9.15625 0.903147 7.85315 1.42429 6.63799C1.94542 5.42284 2.65924 4.36509 3.56575 3.46473C4.47223 2.56438 5.53276 1.85159 6.74733 1.32639C7.96189 0.801179 9.25703 0.538574 10.6327 0.538574C12.0245 0.538574 13.3285 0.800824 14.5448 1.32532C15.761 1.84983 16.819 2.56165 17.7186 3.4608C18.6183 4.35995 19.3306 5.41728 19.8554 6.6328C20.3802 7.84832 20.6426 9.15252 20.6426 10.5454C20.6426 11.9216 20.38 13.2172 19.8548 14.4322C19.3296 15.6472 18.6168 16.7081 17.7164 17.6149C16.8161 18.5217 15.7589 19.2357 14.545 19.7569C13.3311 20.278 12.0287 20.5386 10.6376 20.5386ZM10.6358 18.627C12.8837 18.627 14.7927 17.8409 16.3626 16.2687C17.9324 14.6965 18.7174 12.7887 18.7174 10.5454C18.7174 8.29741 17.9324 6.38848 16.3626 4.8186C14.7927 3.24872 12.8815 2.46378 10.6289 2.46378C8.3925 2.46378 6.48759 3.24872 4.91421 4.8186C3.34083 6.38848 2.55415 8.29969 2.55415 10.5522C2.55415 12.7886 3.34025 14.6936 4.91247 16.2669C6.48468 17.8403 8.39245 18.627 10.6358 18.627Z"
                fill="#fff"
            />
        </svg>
    );
};