import type { TableColumnsType, TableProps } from 'antd';
import { Button, Col, Input, InputNumber, Row, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { ProductType } from './types';
import configurationService from '../../../client-lib/api/configuration-service';
import { IPagination, IQuery, Page } from '../../../lib/types/global-types';
import { toastText } from '../../../components/global/toast';
import { CustomPagination } from '../../../components/global/table-pagination';
import { hasText } from '../../../lib/utils';
import { Dayjs } from 'dayjs';
import { TableActionBar } from './action-bar';
import { useDebounce } from 'use-debounce';
import toast from 'react-hot-toast';
import { openNotification } from '../../../components/global/notificationHelper';
 
import { io } from 'socket.io-client';

const Products = () => {
    const [loading, setLoading] = useState(true);
    const [productData, setProductData] = useState<ProductType[]>([]);
    const [updatedProductData, setUpdatedProductData] = useState<ProductType[]>(
        []
    );
    const [xeroAccountOptions, setXeroAccountOptions] = useState<any>([]);
    const [taxOptions, setTaxOptions] = useState<any>([]);
    const [pagination, setPagination] = useState<IPagination>({
        pageNo: 1,
        pageSize: 10,
    });
    const [page, setPage] = useState<Page<any>>({
        content: [],
        count: 0,
    });
    const [fromDate, setFromDate] = useState<Dayjs | null>(null);
    const [toDate, setToDate] = useState<Dayjs | null>(null);
    const [searchFilter, setSearchFilter] = useState<Dayjs | null>(null);
    const [debouncedSearchText] = useDebounce(searchFilter, 1000);
    const [sort, setSort] = useState({
        sortBy: '',
        sortOrder: 'asc',
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [socket, setSocket] = useState<any>(null);

    useEffect(() => {
        const token = localStorage.getItem('token'); // Replace with your JWT token
        const socket = io(process.env.REACT_APP_WEB_SOCKET_URL as string, {
            query: { token },
            path: '/api/web-socket',
        });

        socket.on('connect', () => {
            console.log('Connected to Socket.IO server');
        });

        socket.on('synced-with-xero-accounts', (message) => {
            console.log(message);
            getXeroAccountList();
        });

        socket.on('disconnect', () => {
            console.log('Disconnected from Socket.IO server');
        });

        setSocket(socket);

        getXeroAccountList();

        return () => {
            socket.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const token = localStorage.getItem('token'); // Replace with your JWT token
        const socket = io(process.env.REACT_APP_WEB_SOCKET_URL as string, {
            query: { token },
            path: '/api/web-socket',
        });

        socket.on('connect', () => {
            console.log('Connected to Socket.IO server');
        });

        socket.on('synced-with-shopify-products', (message) => {
            console.log(message);
            getShopifyOrders();
        });

        socket.on('disconnect', () => {
            console.log('Disconnected from Socket.IO server');
        });

        setSocket(socket);

        getShopifyOrders();

        return () => {
            socket.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOk = () => {
        setUpdatedProductData([]);
        setPagination({
            pageNo: 1,
            pageSize: 10,
        });
    };

    useEffect(() => {
        getXeroAccountList();
        getShopifyOrders();
        getTaxOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination, debouncedSearchText]);

    useEffect(() => {
        const query: IQuery = {
            ...pagination,
        };

        configurationService.getShopifyProducts(query);
    }, []);

    async function getTaxOptions() {
        try {
            let taxOptionList: any = await configurationService.getTaxType();
            const taxOptions = taxOptionList.result.content;
            const formattedOptions = taxOptions.map((tax: any) => ({
                label: tax.taxType,
                value: tax.taxType,
            }));

            setTaxOptions(formattedOptions);

            return taxOptionList;
        } catch (error) {
            console.error('Error fetching Xero account list:', error);
            return [];
        }
    }

    async function getXeroAccountList(): Promise<[]> {
        try {
            let xeroAccountList: any =
                await configurationService.getXeroAccount();
            const xeroAccountNames = xeroAccountList.result.content;
            const formattedOptions = xeroAccountNames.map((account: any) => ({
                label: account.name,
                value: account.code,
            }));
            setXeroAccountOptions(formattedOptions);

            return xeroAccountList;
        } catch (error) {
            console.error('Error fetching Xero account list:', error);
            return [];
        }
    }

    const getShopifyOrders = async (queryParams?: any) => {
        setLoading(true);

        try {
            const query: IQuery = {
                ...pagination,
            };

            fromDate && (query.fromDate = fromDate.format('YYYY-MM-DD'));
            toDate && (query.toDate = toDate.format('YYYY-MM-DD'));

            sort.sortBy && (query.sortBy = sort.sortBy);
            sort.sortOrder && (query.sortingOrder = sort.sortOrder);
            searchFilter && (query.searchFilter = debouncedSearchText);

            const res = await configurationService.getShopifyProducts(query);
            if (res.result) {
                const productsWithKeys = res.result.content.map(
                    (product: ProductType, index: number) => ({
                        ...product,
                        key: String(index + 1),
                    })
                );
                setPage(res.result);
                setProductData(productsWithKeys);
            }
        } catch (error: any) {
            const errorMessage = error.errorDescription || error.message;
            toastText(errorMessage, 'error');
        }
        setLoading(false);
    };

    const onSortChange = (e: any) => {
        const sort = {
            sortBy: e.target.name,
            sortOrder: e.target.value,
        };
        setSort(sort);
        // getShopifyOrders(sort)
    };

    const onSearch = (search: any) => {
        setUpdatedProductData([]);
        setSearchFilter(search);
    };

    const handleSelectChange = (
        value: string,
        key: string,
        field: keyof ProductType
    ): void => {
        setProductData((prevData) => {
            const updatedData = prevData.map((product) => {
                if (product.key === key) {
                    const updatedProduct = { ...product, [field]: value };
                    setUpdatedProductData((prevUpdatedData) => {
                        const updated = prevUpdatedData.filter(
                            (p) => p.key !== key
                        );
                        return [...updated, updatedProduct];
                    });
                    return updatedProduct;
                }
                return product;
            });
            return updatedData;
        });
    };

    const handleInputChange = (
        event: any,
        key: string,
        field: keyof ProductType
    ): void => {
        const inputFieldValue = event;
        setProductData((prevData) => {
            const updatedData = prevData.map((product) => {
                if (product.key === key) {
                    const updatedProduct = {
                        ...product,
                        [field]: inputFieldValue,
                    };
                    setUpdatedProductData((prevUpdatedData) => {
                        const updatedData = prevUpdatedData.filter(
                            (p) => p.key !== key
                        );
                        return [...updatedData, updatedProduct]; // Add updated entry
                    });
                    return updatedProduct;
                }
                return product;
            });
            return updatedData;
        });
    };

    const hasPartialUpdate = (
        originalData: ProductType[],
        updatedData: ProductType[]
    ) => {
        for (let i = 0; i < originalData.length; i++) {
            const original = originalData[i];
            const updated = updatedData.find(
                (data) => data.key === original.key
            );

            if (!updated) continue;

            let hasUpdates = false;
            let hasUnchangedFields = false;

            (Object.keys(original) as (keyof ProductType)[]).forEach((key) => {
                if (original[key] !== updated[key]) {
                    hasUpdates = true;
                } else {
                    hasUnchangedFields = true;
                }
            });

            if (hasUpdates && hasUnchangedFields) {
                return true;
            }
        }

        return false;
    };

    const handleSaveButton = async () => {
        setLoading(true);
        if (!updatedProductData || updatedProductData.length === 0) {
            openNotification('warning', 'Warning', 'No changes have been made to the product configurations')
            setLoading(false);
        } else if (hasPartialUpdate(productData, updatedProductData)) {
            toast.error('Please complete all fields in the row before saving.');
            setLoading(false);
        } else {
            setLoading(true);
            try {
                const response = await configurationService.updateProduct(
                    updatedProductData
                );
                if (response.result?.success) {
                    toastText('Products updated successfully', 'success');
                }
            } catch (error: any) {
                let errorMessage = error.message;

                if (error.errorDescription) {
                    errorMessage = error.errorDescription;
                }

                toastText(errorMessage, 'error');
            }
            setLoading(false);
        }
    };

    const handleSyncShopifyProducts = async () => {
        setLoading(true);

        try {
            const res = await configurationService.syncShopifyProducts();

            if (res.result?.success) {
                toastText('Products syncing started successfully', 'success');
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        }
        setLoading(false);
    };

    const handleSyncXeroAccounts = async () => {
        setLoading(true);

        try {
            const res = await configurationService.syncXeroAccounts();

            if (res.result?.success) {
                toastText('Accounts syncing started successfully', 'success');
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        }
        setLoading(false);
    };

    const columns: TableColumnsType<ProductType> = [
        {
            title: 'Products',
            dataIndex: 'products',
            key: 'products',
            width: '20%',
            // width: '90%', // expandable nested table
        },
        {
            title: 'Sales Price',
            dataIndex: 'salesPrice',
            key: 'salesPrice',
            width: '10%',
            render: (text: any, record) => (
                <InputNumber
                    style={{ width: '100%' }}
                    min={0}
                    max={999999}
                    type="number"
                    value={hasText(text) ? text : '0'}
                    onChange={(event: any) =>
                        handleInputChange(
                            event,
                            String(record.key),
                            'salesPrice'
                        )
                    }
                    parser={(value: any) => {
                        const parsedValue = parseFloat(value || '0');
                        return isNaN(parsedValue) ? 0 : parsedValue;
                    }}
                    // prefix={'$'}
                />
            ),
        },
        {
            title: 'Sales COA',
            dataIndex: 'salesCOA',
            key: 'salesCOA',
            width: '16%',
            render: (text, record) => (
                <Select
                    style={{ width: '100%' }}
                    showSearch
                    onChange={(value) =>
                        handleSelectChange(
                            value,
                            String(record.key),
                            'salesCOA'
                        )
                    }
                    placeholder="Select COA account"
                    filterOption={(input, option) => {
                        const label = option?.label;
                        return (
                            typeof label === 'string' &&
                            label.toLowerCase().includes(input.toLowerCase())
                        );
                    }}
                    value={record.salesCOA || undefined}
                    options={xeroAccountOptions}
                />
            ),
        },
        {
            title: 'Purchase Price',
            dataIndex: 'purchasePrice',
            key: 'purchasePrice',
            width: '10%',
            render: (text, record) => (
                <InputNumber
                    min={0}
                    style={{ width: '100%' }}
                    max={999999}
                    type="number"
                    value={hasText(text) ? text : '0'}
                    onChange={(event: any) =>
                        handleInputChange(
                            event,
                            String(record.key),
                            'purchasePrice'
                        )
                    }
                    parser={(value: any) => {
                        const parsedValue = parseFloat(value || '0');
                        return isNaN(parsedValue) ? 0 : parsedValue;
                    }}
                    // prefix={'$'}
                />
            ),
        },
        {
            title: 'Purchase COA',
            dataIndex: 'purchaseCOA',
            key: 'purchaseCOA',
            width: '16%',
            render: (text, record) => (
                <Select
                    style={{ width: '100%' }}
                    showSearch
                    onChange={(value) =>
                        handleSelectChange(
                            value,
                            String(record.key),
                            'purchaseCOA'
                        )
                    }
                    placeholder="Select purchase Coa account"
                    filterOption={(input, option) => {
                        const label = option?.label;
                        return (
                            typeof label === 'string' &&
                            label.toLowerCase().includes(input.toLowerCase())
                        );
                    }}
                    value={record.purchaseCOA || undefined}
                    options={xeroAccountOptions}
                />
            ),
        },
        {
            title: 'Tax',
            dataIndex: 'tax',
            key: 'tax',
            width: '16%',
            render: (text, record) => (
                <Select
                    style={{ width: '100%' }}
                    showSearch
                    onChange={(value) =>
                        handleSelectChange(value, String(record.key), 'tax')
                    }
                    placeholder="Select tax type"
                    filterOption={(input, option) =>
                        (xeroAccountOptions?.label ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                    }
                    value={record.tax || undefined}
                    options={taxOptions}
                />
            ),
        },
    ];

    // // expandable nested table
    // const nestedTableColumns: TableColumnsType<ProductType> = [
    //     {
    //         title: 'Sales Price',
    //         dataIndex: 'salesPrice',
    //         key: 'salesPrice',
    //         width: '10%',
    //         render: (text, record) => (
    //             <Input
    //                 value={text}
    //                 onChange={(event: any) => handleInputChange(event, String(record.key), 'salesPrice')}
    //             />
    //         )
    //     },
    //     {
    //         title: 'Sales COA',
    //         dataIndex: 'salesCoa',
    //         key: 'salesCoa',
    //         width: '16%',
    //         render: (text, record) => (
    //             <Select
    //                 style={{ width: '100%' }}
    //                 value={record.salesCoa}
    //                 onChange={(value) => handleSelectChange(value, String(record.key), 'salesCoa')}
    //                 options={[
    //                     { key: 0, value: null, label: 'Sales Account', disabled: true },
    //                     { key: 1, value: 'option1', label: 'Option 1' },
    //                     { key: 2, value: 'option2', label: 'Option 2' },
    //                 ]}
    //             />
    //         )
    //     },
    //     {
    //         title: 'Purchase Price',
    //         dataIndex: 'purchasePrice',
    //         key: 'purchasePrice',
    //         width: '10%',
    //         render: (text, record) => (
    //             <Input
    //                 value={text}
    //                 onChange={(event: any) => handleInputChange(event, String(record.key), 'purchasePrice')}
    //             />
    //         )
    //     },
    //     {
    //         title: 'Purchase COA',
    //         dataIndex: 'purchaseCoa',
    //         key: 'purchaseCoa',
    //         width: '16%',
    //         render: (text, record) => (
    //             <Select
    //                 style={{ width: '100%' }}
    //                 value={record.purchaseCoa}
    //                 onChange={(value) => handleSelectChange(value, String(record.key), 'purchaseCoa')}
    //                 options={[
    //                     {key: 0, value: null, label: 'Sales Account', disabled: true },
    //                     {key: 1, value: 'option1', label: 'Option 1' },
    //                     {key: 2, value: 'option2', label: 'Option 2' },
    //                 ]}
    //             />
    //         )
    //     },
    //     {
    //         title: 'Tax',
    //         dataIndex: 'tax',
    //         key: 'tax',
    //         width: '16%',
    //         render: (text, record) => (
    //             <Select
    //                 style={{ width: '100%' }}
    //                 value={record.tax}
    //                 onChange={(value) => handleSelectChange(value, String(record.key), 'tax')}
    //                 options={[
    //                     {key: 0, value: null, label: 'Sales Account', disabled: true },
    //                     {key: 1, value: 'option1', label: 'Option 1' },
    //                     {key: 2, value: 'option2', label: 'Option 2' },
    //                 ]}
    //             />
    //         )
    //     },
    // ];

    const onChange: TableProps<ProductType>['onChange'] = (
        pagination,
        filters,
        sorter,
        extra
    ) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    return (
        <div>
            <Row gutter={24}>
                <Col span={24} style={{ marginBottom: '8px' }}>
                    <TableActionBar
                        fromDate={fromDate}
                        toDate={toDate}
                        onApply={handleOk}
                        onSearch={onSearch}
                        handleSyncShopifyProducts={handleSyncShopifyProducts}
                        handleSyncXeroAccount={handleSyncXeroAccounts}
                        handleSaveButton={handleSaveButton}
                        onChangeFromDate={(date) => setFromDate(date)}
                        onChangeToDate={(date) => setToDate(date)}
                        onSortChange={onSortChange}
                    />
                </Col>
                <Col
                    span={24}
                    style={{
                        height: 'calc(100vh - 249.48px)',
                    }}
                >
                    <Table
                        columns={columns}
                        dataSource={productData}
                        pagination={false}
                        loading={loading}
                        onChange={onChange}
                        scroll={{
                            y: 'calc(100vh - 322px)',
                        }}
                        // expandable nested table
                        // expandable={{
                        //     expandedRowRender: (record) => (
                        //         <Table
                        //             columns={nestedTableColumns}
                        //             dataSource={[record]}
                        //             pagination={false}
                        //         />
                        //     ),
                        // }}
                    />
                </Col>
                {page.count ? (
                    <Col span={24} style={{ marginTop: '10px' }}>
                        <CustomPagination
                            pagination={pagination}
                            counts={page.count}
                            onChangePagination={(data: IPagination) => {
                                setPagination({ ...data });
                            }}
                        />
                    </Col>
                ) : null}
            </Row>
        </div>
    );
};

export default Products;
