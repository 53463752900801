import { Button, Col, Input, Modal, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import authService from '../../../client-lib/api/auth-service';
import { IUserProfileData } from '../../../lib/types/user-types';
import { toastText } from '../toast';
import { fullTrim, hasText } from '../../../lib/utils';

type Props = {
    open: boolean;
    onCancel: (isEdit?: boolean) => void;
    userData?: IUserProfileData | null;
    fetchProfile: any;
};

export const EditProfileModal = ({
    open,
    onCancel,
    userData,
    fetchProfile,
}: Props) => {
    useEffect(() => {
        if (userData) {
            setName(userData.name);
            setRoleId(userData.roleId);
            setEmail(userData.email);
        }
    }, [userData]);

    const [name, setName] = useState('');
    const [roleId, setRoleId] = useState<string | null>(null);
    const [email, setEmail] = useState('');

    const [loading, setLoading] = useState(false);

    const [hasError, setHasError] = useState(false);

    const handleSubmit = async () => {
        try {
            if (!hasText(name)) {
                setHasError(true);
                return;
            }
            setLoading(true);

            const res = await authService.editProfile({
                name,
            });

            if (res.result?.name) {
                toastText('User profile updated successfully', 'success');
                fetchProfile();
                onCancel();
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        }

        setLoading(false);
    };

    return (
        <Modal
            open={open}
            footer={null}
            width={600}
            onCancel={() => onCancel()}
            title={'Edit Profile'}
        >
            <div>
                <form
                    style={{ paddingTop: '20px' }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                >
                    <Row gutter={24}>
                        <Col span={12} style={{ marginBottom: '10px' }}>
                            <Input
                                id="name"
                                placeholder="Name"
                                value={name}
                                size="large"
                                status={
                                    hasError && !hasText(name) ? 'error' : ''
                                }
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    if (/^[a-zA-Z\s]*$/.test(newValue)) {
                                        setName(newValue);
                                    }
                                }}
                                onBlur={(e) =>
                                    setName(fullTrim(e.target.value))
                                }
                                style={{
                                    width: '100%',
                                }}
                            />
                            {hasError && !hasText(name) ? (
                                <p style={{ color: '#ff4d4f' }}>
                                    Please enter your name
                                </p>
                            ) : null}
                        </Col>
                        <Col span={12} style={{ marginBottom: '10px' }}>
                            <Select
                                id="role"
                                placeholder="Select Role"
                                value={roleId}
                                options={[
                                    {
                                        label: userData?.roleName,
                                        value: roleId,
                                    },
                                ]}
                                size="large"
                                style={{
                                    width: '100%',
                                }}
                                disabled={true}
                            />
                        </Col>
                        <Col span={24} style={{ marginBottom: '20px' }}>
                            <Input
                                id="email"
                                placeholder="Email"
                                value={email}
                                size="large"
                                style={{
                                    width: '100%',
                                }}
                                disabled
                            />
                        </Col>
                        <Col span={24}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    loading={loading}
                                >
                                    Edit Profile
                                </Button>
                                <Button
                                    size="large"
                                    htmlType="button"
                                    style={{
                                        marginLeft: '10px',
                                    }}
                                    onClick={() => onCancel()}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </form>
            </div>
        </Modal>
    );
};
