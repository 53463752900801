import { Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { UserPage } from './Components/Users';
import { RolesPage } from './Components/Roles';

export const UserAndRoles = () => {
    const [searchParams, setSearchparams] = useSearchParams();

    const [activeTab, setActiveTab] = useState(
        searchParams.get('activeTab') || 'user'
    );

    const items = [
        {
            key: 'user',
            label: 'Users',
        },
        {
            key: 'role',
            label: 'Roles',
        },
    ];

    useEffect(() => {
        setActiveTab(searchParams.get('activeTab') || 'user');
    }, [searchParams]);

    return (
        <div>
            <Tabs
                activeKey={activeTab}
                items={items}
                onChange={(key) => {
                    setSearchparams({
                        activeTab: key,
                    });
                }}
                size="large"
            />
            <div>
                {activeTab === 'user' ? <UserPage /> : null}

                {activeTab === 'role' ? <RolesPage /> : null}
            </div>
        </div>
    );
};
