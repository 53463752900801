import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import connectionService from '../../client-lib/api/connection-service';
import { toastText } from '../../components/global/toast';
import styles from './index.module.css';

export const Callback = () => {
    const [isLoading, setIsLoading] = useState(false);
    // const [isConnected, setIsConnected] = useState(false);
    // const [connectionStatus, setConnectionStatus] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [queryParams] = useSearchParams();

    useEffect(() => {
        if (location.pathname.startsWith('/xero/callback')) {
            connectXero(window.location.href);
        }
        if (location.pathname.startsWith('/shopify/callback')) {
            // Handle Shopify callback
            const code = queryParams.get('code');
            const shop = queryParams.get('shop');
            connectShopify(code as string, shop as string);
        }
        if (location.pathname.startsWith('/stripe/callback')) {
            // Handle Shopify callback
            const code = queryParams.get('code');
            connectStripe(code as string);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const connectXero = async (currentUrl: string) => {
        try {
            setIsLoading(true);
            const data = {
                url: currentUrl,
            };
            const response = await connectionService.xeroCallback(data);
            if (response.result) {
                toastText('Connected to Xero successfully', 'success');
                // setConnectionStatus(true);
                // setIsConnected(true);
            }
        } catch (error: any) {
            toastText(error.message, 'error');
            // setConnectionStatus(error.message);
            // setIsConnected(false);
        } finally {
            setIsLoading(false);
            navigate('/integrations');
        }
    };

    const connectShopify = async (code: string, shop: string) => {
        try {
            setIsLoading(true);
            const data = {
                code: code,
                shop: shop,
            };
            const response = await connectionService.shopifyCallback(data);
            if (response.result) {
                toastText('Connected to Shopify successfully', 'success');
                // setConnectionStatus(true);
                // setIsConnected(true);
            }
        } catch (error: any) {
            toastText(error.message, 'error');
            // setConnectionStatus(error.message);
            // setIsConnected(false);
        } finally {
            setIsLoading(false);
            navigate('/integrations');
        }
    };
    const connectStripe = async (code: string) => {
        try {
            setIsLoading(true);
            const data = {
                code: code,
            };
            const response = await connectionService.stripeCallback(data);
            if (response.result) {
                toastText('Connected to stripe successfully', 'success');
                // setConnectionStatus(true);
                // setIsConnected(true);
            }
        } catch (error: any) {
            toastText(error.message, 'error');
            // setConnectionStatus(error.message);
            // setIsConnected(false);
        } finally {
            setIsLoading(false);
            navigate('/integrations');
        }
    };
    return (
        <>
            <div className={styles['container']}>
                {isLoading ? <Spin size="large" spinning={isLoading} /> : null}
            </div>
        </>
    );
};
