import { Modal } from 'antd';
import { formatNumberWithCommasV2 } from '../../lib/utils';
import { Pie } from 'react-chartjs-2';
import './index.css';
interface GraphInterface {
    chartData: any;
    isModalOpen: boolean;
    handleCancel: () => void;
    displayTitle: string;
}
const GraphModal = (props: GraphInterface) => {
    const { isModalOpen, handleCancel, chartData, displayTitle } = props;
    
    return (
        <Modal
            open={isModalOpen}
            onCancel={handleCancel}
            width={'75%'}
            footer={false}
            title={displayTitle}
            centered
            className="pie-chart-modal"
        >

            <Pie
                data={chartData}
                options={{
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: true,
                            position: 'bottom',
                            labels: {
                                boxWidth: 10,
                                color: 'black',
                            },
                        },
                        tooltip: {
                            enabled: true,
                            callbacks: {
                                label: (tooltipItem: any) => {
                                    return `${formatNumberWithCommasV2(tooltipItem.raw)}%`;
                                },
                            },
                        },
                    },
                }}
                className="modal-chart"
            />
        </Modal>
    );
};

export default GraphModal;
