import { DisconnectOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row } from 'antd';
import React, { useState } from 'react';
import connectionService from '../../../client-lib/api/connection-service';
import { toastText } from '../../../components/global/toast';
type Props = {
    open: boolean;
    onCancel: (isEdit?: boolean) => void;
    moduleName: any;
};
export const DisconnectModal = ({ open, onCancel, moduleName }: Props) => {
    const [loading, setLoading] = useState(false);
    const handleSubmit = async () => {
        try {
            setLoading(true);
            if (moduleName === 'hubSpot') {
                const response = await connectionService.disconnectHubSpot();
                if (response.result?.success) {
                    toastText('Hubspot disconnected successfully', 'success');
                }
            }
            if (moduleName === 'woocommerce') {
                const response =
                    await connectionService.disconnectWoocommerce();
                if (response.result?.success) {
                    toastText(
                        'Woocommerce disconnected successfully',
                        'success'
                    );
                }
            }
            if (moduleName === 'xero') {
                const response = await connectionService.disconnectXero();
                if (response.result?.success) {
                    toastText('Xero disconnected successfully', 'success');
                }
            }
            if (moduleName === 'linnworks') {
                const response = await connectionService.disconnectLinnworks();
                if (response.result?.success) {
                    toastText('Linnworks disconnected successfully', 'success');
                }
            }
            if (moduleName === 'shopify') {
                const response = await connectionService.disconnectShopify();
                if (response.result?.success) {
                    toastText('Shopify disconnected successfully', 'success');
                }
            }
            if (moduleName === 'stripe') {
                const response = await connectionService.disconnectStripe();
                if (response.result?.success) {
                    toastText('Stripe disconnected successfully', 'success');
                }
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        }
        setLoading(false);
        onCancel();
    };
    return (
        <Modal
            open={open}
            footer={null}
            width={500}
            onCancel={() => onCancel()}
            title={''}
        >
            <div>
                <Row gutter={24}>
                    <Col span={24} style={{ marginBottom: '20px' }}>
                        <div className="delete-icon">
                            <DisconnectOutlined
                                style={{ color: 'red', fontSize: '35px' }}
                            />
                        </div>
                        <div className="delete-text">
                            Are you sure you want to disconnect?
                        </div>
                    </Col>
                    <Col span={24}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="middle"
                                loading={loading}
                                onClick={handleSubmit}
                            >
                                Disconnect {moduleName}
                            </Button>
                            <Button
                                size="middle"
                                htmlType="button"
                                style={{
                                    marginLeft: '10px',
                                }}
                                onClick={() => onCancel()}
                            >
                                Cancel
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
};
