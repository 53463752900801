import { Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { B2BOrders } from './Components/B2BOrders';
import { B2COrders } from './Components/B2COrders';
import { COGSSynclogs } from './Components/COGSSynclogs';

export const SyncLogs = () => {
    const [searchParams, setSearchparams] = useSearchParams();

    const [activeTab, setActiveTab] = useState(
        searchParams.get('activeTab') || 'B2BOrders'
    );

    const items = [
        {
            key: 'B2BOrders',
            label: 'B2B Orders',
        },
        {
            key: 'B2COrders',
            label: 'B2C Orders',
        },
        // {
        //     key: 'COGSSynclogs',
        //     label: 'COGS Synclogs',
        // },
    ];

    useEffect(() => {
        setActiveTab(searchParams.get('activeTab') || 'B2BOrders');
    }, [searchParams]);

    return (
        <div>
            <Tabs
                activeKey={activeTab}
                items={items}
                onChange={(key) => {
                    setSearchparams({
                        activeTab: key,
                    });
                }}
                size="large"
            />
            <div>
                {activeTab === 'B2BOrders' ? <B2BOrders /> : null}

                {activeTab === 'B2COrders' ? <B2COrders /> : null}

                {/* {activeTab === 'COGSSynclogs' ? <COGSSynclogs /> : null} */}
            </div>
        </div>
    );
};
