import { RouterProvider } from 'react-router-dom';
import './App.css';
import router from './Router';
import { SystemToaster } from './components/global/toaster';

function App() {
    return (
        <div className="App">
            <RouterProvider router={router} />
            <SystemToaster />
        </div>
    );
}

export default App;
