import { createBrowserRouter } from 'react-router-dom';
import { Home } from './pages/Home';
import { Login } from './pages/Login';
import SecurityConfiguration from './components/auth/security-configuration';
import { SyncLogs } from './pages/SyncLogs';
import { SalesReport } from './pages/SalesReport';
import { UserAndRoles } from './pages/UserRoles';
import { Integrations } from './pages/Integrations';
import { Configurations } from './pages/Configurations';
import { ForgotPassword } from './pages/ForgotPassword';
import { ResetPassword } from './pages/ResetPassword';
import { Callback } from './pages/Callback';

const router = createBrowserRouter([
    {
        element: <SecurityConfiguration />,
        children: [
            {
                path: '/',
                element: <Home />,
            },
            {
                path: '/sync-logs',
                element: <SyncLogs />,
            },
            {
                path: '/sales-report',
                element: <SalesReport />,
            },
            {
                path: '/user-roles',
                element: <UserAndRoles />,
            },
            {
                path: '/integrations',
                element: <Integrations />,
            },
            {
                path: '/configurations',
                element: <Configurations />,
            },
            {
                path: '/xero/callback',
                element: <Callback />,
            },
            {
                path: '/shopify/callback',
                element: <Callback />,
            },
            {
                path: '/stripe/callback',
                element: <Callback />,
            },
        ],
    },
    {
        path: '/login',
        element: <Login />,
    },
    {
        path: '/forgot-password',
        element: <ForgotPassword />,
    },
    {
        path: '/reset-password',
        element: <ResetPassword />,
    },
]);

export default router;
