import { FilterOutlined } from '@ant-design/icons';
import {
    Button,
    DatePicker,
    Input,
    Menu,
    Popconfirm,
    Radio,
    RadioChangeEvent,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { forwardRef, useState } from 'react';
import styles from './index.module.css';
import GlobalDatePicker from './globalDatePicker';

type Props = {
    fromDate: Dayjs | null;
    toDate: Dayjs | null;
    onApply: () => void;
    onChangeFromDate: (date: Dayjs | null) => void;
    onChangeToDate: (date: Dayjs | null) => void;
    handleSaveButton: () => void;
    handleSyncShopifyProducts: () => void;
    handleSyncXeroAccount: () => void;
    onSearch: (productName: any) => void;
    onSortChange: ({ target: { value } }: RadioChangeEvent) => void;
};

export const TableActionBar = forwardRef<HTMLDivElement, Props>(
    (
        {
            fromDate,
            toDate,
            onApply,
            onChangeFromDate,
            onChangeToDate,
            handleSaveButton,
            handleSyncShopifyProducts,
            handleSyncXeroAccount,
            onSearch,
            onSortChange,
        },
        ref
    ) => {
        const [selectedKey, setSelectedKey] = useState('date');

        const items = [
            {
                key: 'date',
                label: 'Date',
            },
            {
                key: 'sortBy',
                label: 'Sort By',
            },
        ];

        const handleMenuClick = (e: any) => {
            setSelectedKey(e.key);
        };

        const handleCancel = () => {
            setSelectedKey('date');
        };

        const sortOptions = [
            { label: 'Asc', value: 'asc' },
            { label: 'Desc', value: 'desc' },
        ];

        return (
            <div
                ref={ref}
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Popconfirm
                    placement="rightBottom"
                    title={false}
                    description={
                        <div className={styles['filer-content']}>
                            <Menu
                                mode="vertical"
                                items={items}
                                onClick={handleMenuClick}
                                selectedKeys={[selectedKey]}
                                defaultSelectedKeys={[selectedKey]}
                            />
                            {selectedKey === 'date' && (
                                <div>
                                    <GlobalDatePicker
                                        label="From Date"
                                        value={fromDate}
                                        onChange={onChangeFromDate}
                                        disabledDate={(current) => {
                                            const flag = toDate && current && current > dayjs(toDate);
                                            return Boolean(flag);
                                        }}
                                    />

                                    <GlobalDatePicker
                                        label="To Date"
                                        value={toDate}
                                        onChange={onChangeToDate}
                                        disabledDate={(current) => {
                                            const flag = fromDate && current && current < dayjs(fromDate);
                                            return Boolean(flag);
                                        }}
                                    />
                                </div>
                            )}
                            {selectedKey === 'sortBy' && (
                                <div>
                                    <p>Create Date</p>
                                    <Radio.Group
                                        name="createdAt"
                                        options={sortOptions}
                                        onChange={onSortChange}
                                        defaultValue={'desc'}
                                    />
                                </div>
                            )}
                        </div>
                    }
                    okText="Apply"
                    cancelText="Cancel"
                    onConfirm={() => onApply && onApply()}
                    onCancel={handleCancel}
                    icon={false}
                >
                    <Button
                        type="primary"
                        size="large"
                        icon={<FilterOutlined />}
                    >
                        Filters
                    </Button>
                </Popconfirm>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <Input
                        placeholder="Search Product Name"
                        allowClear
                        size="large"
                        onChange={(e) => onSearch(e.target.value)}
                    />
                    <Button
                        type="primary"
                        size="large"
                        onClick={handleSyncXeroAccount}
                    >
                        Sync chart of accounts
                    </Button>
                    <Button
                        type="primary"
                        size="large"
                        onClick={handleSyncShopifyProducts}
                    >
                        Sync products
                    </Button>
                    <Button
                        type="primary"
                        size="large"
                        onClick={handleSaveButton}
                    >
                        Save
                    </Button>
                </div>
            </div>
        );
    }
);
