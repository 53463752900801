import { Outlet } from 'react-router-dom'
import { Navbar } from './header';
import style from './index.module.css'

export const PageLayout = () => {

    return (
        <div>
            <Navbar />
            <div className={style['main-content']} >
                <Outlet />
            </div>
        </div>
    )
}