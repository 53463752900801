import http from '../http';

const BASE_PATH = process.env.REACT_APP_API_ENDPOINT;

const hubSpotAuth = async (data: any) => {
    return http.post(`${BASE_PATH}/v1/connection/hubspot/auth`, {
        body: data,
    });
};

const linnworksAuth = async (data: any) => {
    return http.post(`${BASE_PATH}/v1/connection/linnworks/auth`, {
        body: data,
    });
};

const xeroAuth = async () => {
    return http.get(`${BASE_PATH}/v1/connection/xero/auth`);
};

const xeroCallback = async (url: any) => {
    return http.post(`${BASE_PATH}/v1/connection/xero/callback`, { body: url });
};

const wooCommerceAuth = async (data: any) => {
    return http.post(`${BASE_PATH}/v1/connection/woocommerce/auth`, {
        body: data,
    });
};

const shopifyAuth = async (data: any) => {
    return http.get(`${BASE_PATH}/v1/connection/shopify/auth?shopName=${data.shopName}`);
};

const getConnection = async () => {
    return http.get(`${BASE_PATH}/v1/connection`);
};

const disconnectHubSpot = async () => {
    return http.post(`${BASE_PATH}/v1/connection/hubspot/disconnect`);
};

const disconnectWoocommerce = async () => {
    return http.post(`${BASE_PATH}/v1/connection/woocommerce/disconnect`);
};

const disconnectXero = async () => {
    return http.post(`${BASE_PATH}/v1/connection/xero/disconnect`);
};

const disconnectLinnworks = async () => {
    return http.post(`${BASE_PATH}/v1/connection/linnworks/disconnect`);
};

const shopifyCallback = async (data: any) => {
    return http.post(`${BASE_PATH}/v1/connection/shopify/callback`, { body: data });
};

const disconnectShopify = async () => {
    return http.post(`${BASE_PATH}/v1/connection/shopify/disconnect`);
};

const stripeAuth = async () => {
    return http.get(`${BASE_PATH}/v1/connection/stripe/auth`);
};

const stripeCallback = async (data: any) => {
    return http.post(`${BASE_PATH}/v1/connection/stripe/callback`, { body: data });
};

const disconnectStripe = async () => {
    return http.post(`${BASE_PATH}/v1/connection/stripe/disconnect`);
};

const connectionService = {
    hubSpotAuth,
    linnworksAuth,
    xeroAuth,
    xeroCallback,
    wooCommerceAuth,
    shopifyAuth,
    getConnection,
    disconnectHubSpot,
    disconnectWoocommerce,
    disconnectXero,
    disconnectLinnworks,
    shopifyCallback,
    disconnectShopify,
    stripeAuth,
    stripeCallback,
    disconnectStripe
};

export default connectionService;
