import { IQuery, Page, Result } from '../../lib/types/global-types';
import http from '../http';

const BASE_PATH = process.env.REACT_APP_API_ENDPOINT;

const getSynclogs = async (query: IQuery): Promise<Result<Page<any>>> => {
    return http.get(`${BASE_PATH}/v1/synclogs`, {
        queryParams: query,
    });
};

const getB2CSynclogs = async (query: IQuery): Promise<Result<Page<any>>> => {
    return http.get(`${BASE_PATH}/v1/synclogs/b2c/synclogs`, {
        queryParams: query,
    });
};

const syncNow = async () => {
    return http.get(`${BASE_PATH}/v1/invoke/woocommerce/order`);
};
const syncShopifyOrder = async () => {
    return http.get(`${BASE_PATH}/v1/invoke/shopify/orders`);
};

const syncInvoice = async () => {
    return http.get(`${BASE_PATH}/v1/invoke/hubspot/invoice`);
};

const syncPayment = async () => {
    return http.get(`${BASE_PATH}/v1/invoke/hubspot/payment`);
};

const syncJournals = async () => {
    return http.get(`${BASE_PATH}/v1/invoke/journals`);
};

const getCOGS = async () => {
    return http.get(`${BASE_PATH}/v1/synclogs/cogs`);
};

const syncOrderTracking = async () => {
    return http.get(`${BASE_PATH}/v1/invoke/linnworks/orderTracking`);
};

const syncStock = async () => {
    return http.get(`${BASE_PATH}/v1/invoke/linnworks/stock`);
};

const getOrdersByPaymentGatewayAndDate = async (query: { date: string; paymentGateway: string }) => {
    return http.get(`${BASE_PATH}/v1/synclogs/shopify/orders`, {
        queryParams: query,
    });
};

const synclogService = {
    getSynclogs,
    getB2CSynclogs,
    syncNow,
    syncShopifyOrder,
    syncInvoice,
    syncPayment,
    syncJournals,
    getCOGS,
    syncOrderTracking,
    syncStock,
    getOrdersByPaymentGatewayAndDate,
};

export default synclogService;
