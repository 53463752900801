import { Button, Col, Modal, Row } from 'antd';
import { IUser } from './types';
import { useState } from 'react';
import { toastText } from '../../../../components/global/toast';
import userService from '../../../../client-lib/api/user-service';
import { DeleteFilled } from '@ant-design/icons';

type Props = {
    open: boolean;
    onCancel: (isEdit?: boolean) => void;
    userData: IUser | null;
};

export const DeleteUserModal = ({ open, onCancel, userData }: Props) => {
    const [loading, setLoading] = useState(false);
    const handleSubmit = async () => {
        try {
            setLoading(true);
            if (userData) {
                const res = await userService.deleteUser(userData.id);
                if (res.result?.success) {
                    toastText('User deleted successfully', 'success');
                }
            }
            onCancel(true);
        } catch (error: any) {
            let errorMessage = error.message;
            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }
            toastText(errorMessage, 'error');
        }
        setLoading(false);
    };

    return (
        <Modal
            open={open}
            footer={null}
            width={600}
            onCancel={() => onCancel(false)}
            title={userData ? 'Delete User' : ''}
        >
            <div>
                <Row gutter={24}>
                    <Col span={24} style={{ marginBottom: '20px' }}>
                        <div className="delete-icon">
                            <DeleteFilled />
                        </div>
                        <div className="delete-text">
                            Are you sure you want to delete user:{' '}
                            <b>{userData?.name}</b>?
                        </div>
                    </Col>
                    <Col span={24}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                loading={loading}
                                onClick={handleSubmit}
                            >
                                Delete User
                            </Button>
                            <Button
                                size="large"
                                htmlType="button"
                                style={{
                                    marginLeft: '10px',
                                }}
                                onClick={() => onCancel()}
                            >
                                Cancel
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
};
