import { DeleteOutlined, EditOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Switch, Table, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import roleService from '../../../../client-lib/api/role-service';
import userService from '../../../../client-lib/api/user-service';
import { CustomPagination } from '../../../../components/global/table-pagination';
import { toastText } from '../../../../components/global/toast';
import { ROLE_NAME } from '../../../../lib/constants/data';
import {
    IOptions,
    IPagination,
    IQuery,
    Page,
} from '../../../../lib/types/global-types';
import { hasText } from '../../../../lib/utils';
import { Role } from '../Roles/types';
import { SaveUserModal } from './create-modal';
import { DeleteUserModal } from './delete-modal';
import { IUser } from './types';

export const UserPage = () => {
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [debouncedSearchText] = useDebounce(searchText, 1000);

    const [roles, setRoles] = useState<IOptions[]>([]);

    const [openUserModal, setOpenUserModal] = useState(false);
    const [openUserDeleteModal, setOpenUserDeleteModal] = useState(false);
    const [selectedUserData, setSelectedUserData] = useState<IUser | null>(
        null
    );

    const [page, setPage] = useState<Page<IUser>>({
        content: [],
        count: 0,
    });

    const [pagination, setPagination] = useState<IPagination>({
        pageNo: 1,
        pageSize: 10,
    });

    useEffect(() => {
        getAllRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination, debouncedSearchText]);

    useEffect(() => {
        setPagination({
            pageNo: 1,
            pageSize: 10,
        });
    }, [debouncedSearchText]);

    const getAllUsers = async () => {
        setLoading(true);
        try {
            const query: IQuery = {
                ...pagination,
            };

            if (hasText(debouncedSearchText)) {
                query.text = debouncedSearchText;
            }

            const res = await userService.getAllUser(query);

            if (res.result) {
                setPage(res.result);
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        }
        setLoading(false);
    };

    const getAllRoles = async () => {
        try {
            const query: IQuery = {
                ...pagination,
            };

            if (hasText(debouncedSearchText)) {
                query.text = debouncedSearchText;
            }

            const res = await roleService.getAllRoles();

            if (res.result?.length) {
                const _roles = res.result
                    .filter((role: Role) => role.type !== ROLE_NAME.SUPER_ADMIN)
                    .map((role: Role) => {
                        return {
                            label: role.name,
                            value: role.id,
                        };
                    });
                setRoles(_roles);
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            console.log(errorMessage, 'error');
        }
    };
    const updateUserStatus = async (id: string, status: boolean) => {
        setLoading(true);
        const statusText = status ? 'Active' : 'Inactive';
        try {
            const res = await userService.updateUserStatus(id, statusText);

            if (res.result?.success) {
                await getAllUsers();
                toastText(`User ${statusText} successfully`, 'success');
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        }
        setLoading(false);
    };

    const reinviteUser = async (email: string) => {
        setLoading(true);
        try {
            const res = await userService.reinviteUser(email);

            if (res.result?.success) {
                toastText('User reinvited successfully', 'success');
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        }
        setLoading(false);
    };
    return (
        <div>
            <Row gutter={24}>
                <Col span={24} style={{ marginBottom: '10px' }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div>
                            <Input
                                size="large"
                                placeholder="Search by Name, Email"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                style={{
                                    width: '250px',
                                }}
                            />
                        </div>
                        <div>
                            <Button
                                type="primary"
                                size="large"
                                onClick={() => setOpenUserModal(true)}
                            >
                                Invite User
                            </Button>
                        </div>
                    </div>
                </Col>
                <Col
                    span={24}
                    style={{
                        height: 'calc(100vh - 249.48px)',
                    }}
                >
                    <Table
                        pagination={false}
                        loading={loading}
                        dataSource={page.content}
                        scroll={{
                            y: 'calc(100vh - 300px)',
                        }}
                        rowKey={(data: IUser) => {
                            return data.id;
                        }}
                        columns={[
                            {
                                title: 'Name',
                                dataIndex: 'name',
                                key: 'name',
                            },
                            {
                                title: 'Email',
                                dataIndex: 'email',
                                key: 'email',
                            },
                            {
                                title: 'Role',
                                dataIndex: 'role',
                                key: 'role',
                            },
                            {
                                title: 'Status',
                                dataIndex: 'status',
                                key: 'status',
                                render: (value: boolean, record: IUser) => {
                                    return record.roleType !==
                                        ROLE_NAME.SUPER_ADMIN ? (
                                        <Switch
                                            checked={value}
                                            onChange={(flag: boolean) => {
                                                updateUserStatus(
                                                    record.id,
                                                    flag
                                                );
                                            }}
                                        />
                                    ) : (
                                        'Active'
                                    );
                                },
                            },
                            {
                                title: 'Action',
                                dataIndex: 'action',
                                key: 'action',
                                render: (_, record: IUser) => {
                                    return record.roleType !==
                                        ROLE_NAME.SUPER_ADMIN ? (
                                        <>
                                            <Tooltip
                                                title="Reinvite User"
                                                placement="top"
                                            >
                                                <Button
                                                    type="primary"
                                                    className="mr-1"
                                                    icon={<MailOutlined />}
                                                    onClick={() => {
                                                        reinviteUser(
                                                            record.email
                                                        );
                                                    }}
                                                />
                                            </Tooltip>
                                            <Tooltip
                                                title="Edit User"
                                                placement="top"
                                            >
                                                <Button
                                                    type="primary"
                                                    className="mr-1"
                                                    icon={<EditOutlined />}
                                                    onClick={() => {
                                                        setSelectedUserData(
                                                            record
                                                        );
                                                        setOpenUserModal(true);
                                                    }}
                                                />
                                            </Tooltip>
                                            <Tooltip
                                                title="Delete User"
                                                placement="top"
                                            >
                                                <Button
                                                    type="primary"
                                                    icon={<DeleteOutlined />}
                                                    onClick={() => {
                                                        setSelectedUserData(
                                                            record
                                                        );
                                                        setOpenUserDeleteModal(
                                                            true
                                                        );
                                                    }}
                                                />
                                            </Tooltip>
                                        </>
                                    ) : null;
                                },
                            },
                        ]}
                    />
                </Col>
                {page.count ? (
                    <Col span={24} style={{ marginTop: '10px' }}>
                        <CustomPagination
                            pagination={pagination}
                            counts={page.count}
                            onChangePagination={(data: IPagination) => {
                                setPagination({ ...data });
                            }}
                        />
                    </Col>
                ) : null}
            </Row>
            {openUserModal ? (
                <SaveUserModal
                    open={openUserModal}
                    onCancel={(flag) => {
                        setOpenUserModal(false);
                        setSelectedUserData(null);
                        if(flag) {
                            getAllUsers();
                        }
                    }}
                    roles={roles}
                    userData={selectedUserData}
                />
            ) : null}
            {openUserDeleteModal ? (
                <DeleteUserModal
                    open={openUserDeleteModal}
                    onCancel={(flag) => {
                        setOpenUserDeleteModal(false);
                        setSelectedUserData(null);
                        if(flag) {
                            getAllUsers();
                        }
                    }}
                    userData={selectedUserData}
                />
            ) : null}
        </div>
    );
};
