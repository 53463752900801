import React from 'react';
import { DatePicker } from 'antd';
import { Dayjs } from 'dayjs';

type DatePickerProps = {
    label: string;
    value: Dayjs | null;
    onChange: (date: Dayjs | null) => void;
    disabledDate?: (current: Dayjs | null) => boolean;
};

const GlobalDatePicker: React.FC<DatePickerProps> = ({ label, value, onChange, disabledDate }) => {
    return (
        <div>
            <p>{label}</p>
            <DatePicker
                format={{
                    format: 'MM/DD/YYYY',
                    type: 'mask',
                }}
                style={{ marginBottom: '10px' }}
                onChange={onChange}
                disabledDate={disabledDate}
                value={value}
            />
        </div>
    );
};

export default GlobalDatePicker;
