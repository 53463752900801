import { Button, Col, Input, Row } from 'antd';
import styles from './index.module.css';
import { useState } from 'react';
import { fullTrim, hasText } from '../../lib/utils';
import authService from '../../client-lib/api/auth-service';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toastText } from '../../components/global/toast';

export const ResetPassword = () => {
    const [searchParams] = useSearchParams();

    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [showError, setShowError] = useState(false);

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!hasText(password) || !hasText(confirmPassword)) {
            setShowError(true);
            return;
        }

        setLoading(true);
        try {
            const res = await authService.resetPassword({
                password,
                confirmPassword,
                token: searchParams.get('token'),
            });

            if (res?.result?.success) {
                navigate('/login');
                toastText(
                    'Your password has been reset successfully',
                    'success'
                );
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        }
        setLoading(false);
    };

    return (
        <div className={styles['login-container']}>
            <div className={styles['login-img']}>
                <img
                    src="/crystal-clear-logo.webp"
                    alt="Crystal Clear"
                    height={55}
                />
            </div>
            <form onSubmit={handleSubmit}>
                <Row gutter={24}>
                    <Col
                        span={24}
                        className="mt-20"
                        style={{ textAlign: 'center' }}
                    >
                        <p>Reset Password</p>
                    </Col>
                    <Col span={24} className="mt-20">
                        <Input.Password
                            status={
                                showError && !hasText(password) ? 'error' : ''
                            }
                            placeholder="Password"
                            style={{
                                width: '100%',
                            }}
                            size="large"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            onBlur={(e) =>
                                setPassword(fullTrim(e.target.value))
                            }
                        />
                    </Col>
                    <Col span={24} className="mt-20">
                        <Input.Password
                            status={
                                showError && !hasText(confirmPassword)
                                    ? 'error'
                                    : ''
                            }
                            placeholder="Confirm Password"
                            style={{
                                width: '100%',
                            }}
                            size="large"
                            value={confirmPassword}
                            onChange={(e) => {
                                setConfirmPassword(e.target.value);
                            }}
                            onBlur={(e) =>
                                setPassword(fullTrim(e.target.value))
                            }
                        />
                    </Col>
                    <Col span={24} className="mt-20">
                        <Button
                            htmlType="submit"
                            size="large"
                            style={{
                                width: '100%',
                            }}
                            type="primary"
                            loading={loading}
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </div>
    );
};
