import http from '../http';

const BASE_PATH = process.env.REACT_APP_API_ENDPOINT;

const getAllRoles = async () => {
    return http.get(`${BASE_PATH}/v1/roles`);
};

const updateRoleStatus = async (id: string, status: string) => {
    return http.put(`${BASE_PATH}/v1/roles/${id}/status/${status}`);
};

const roleService = {
    getAllRoles,
    updateRoleStatus,
};

export default roleService;
