import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import authService from '../../client-lib/api/auth-service';
import { decodeToken } from '../../client-lib/jwt-service';
import { ROLE_NAME } from '../../lib/constants/data';
import UserNameBox from '../global/UserProfile';
import styles from './index.module.css';

const salesReportsRole = [
    ROLE_NAME.SUPER_ADMIN,
    ROLE_NAME.SALES_REP,
    ROLE_NAME.ACCOUNT_HEADS,
];
const configurationRole = [ROLE_NAME.SUPER_ADMIN, ROLE_NAME.ACCOUNT_HEADS];

export const Navbar = () => {
    const location = useLocation();

    const [isAdmin, setIsAdmin] = useState(false);
    const [isInventoryManager, setIsInventoryManager] = useState(false);
    const [userData, setUserData] = useState<any>({});
    const [userProfile, setUserProfile] = useState(null);

    const fetchProfile = async () => {
        try {
            const response = await authService.fetchProfile();
            setUserProfile(response?.result);
        } catch (error: any) {
            console.log('error: ', error);
        }
    };
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const user: any = decodeToken(token);
            setIsAdmin(user.type === ROLE_NAME.SUPER_ADMIN);
            setIsInventoryManager(user.type === ROLE_NAME.INVENTORY_MANAGER);
            setUserData(user);
        }
        fetchProfile();
    }, []);

    return (
        <header className={styles.header}>
            <Link to={'/'}>
                <div className={styles['header-logo']}>
                    <img
                        src="/crystal-clear-logo.webp"
                        alt="crystal clear"
                        height={40}
                    />
                </div>
            </Link>
            <div className={styles['header-menus']}>
                {!isInventoryManager && (
                    <Link to={'/'} className={`${styles['header-menu']} ml-20`}>
                        <div
                            className={
                                location.pathname === '/' ? 'active-route' : ''
                            }
                        >
                            Dashboard
                        </div>
                    </Link>
                )}

                <Link
                    to={'/sync-logs'}
                    className={`${styles['header-menu']} ml-20`}
                >
                    <div
                        className={
                            location.pathname === '/sync-logs'
                                ? 'active-route'
                                : ''
                        }
                    >
                        Sync Logs
                    </div>
                </Link>
                {/* {salesReportsRole.includes(userData?.type) ? (
                    <Link
                        to={'/sales-report'}
                        className={`${styles['header-menu']} ml-20`}
                    >
                        <div
                            className={
                                location.pathname === '/sales-report'
                                    ? 'active-route'
                                    : ''
                            }
                        >
                            Sales Report
                        </div>
                    </Link>
                ) : null} */}
                {isAdmin ? (
                    <Link
                        to={'/user-roles'}
                        className={`${styles['header-menu']} ml-20`}
                    >
                        <div
                            className={
                                location.pathname === '/user-roles'
                                    ? 'active-route'
                                    : ''
                            }
                        >
                            Users & Roles
                        </div>
                    </Link>
                ) : null}
                {isAdmin ? (
                    <Link
                        to={'/integrations'}
                        className={`${styles['header-menu']} ml-20`}
                    >
                        <div
                            className={
                                location.pathname === '/integrations'
                                    ? 'active-route'
                                    : ''
                            }
                        >
                            Integrations
                        </div>
                    </Link>
                ) : null}
                {configurationRole.includes(userData?.type) ? (
                    <Link
                        to={'/configurations'}
                        className={`${styles['header-menu']} ml-20`}
                    >
                        <div
                            className={
                                location.pathname === '/configurations'
                                    ? 'active-route'
                                    : ''
                            }
                        >
                            Configurations
                        </div>
                    </Link>
                ) : null}
            </div>
            <div>
                <UserNameBox
                    fetchProfile={fetchProfile}
                    userData={userProfile}
                />
            </div>
        </header>
    );
};
