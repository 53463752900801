import { Button, DatePicker, Menu, Popconfirm, Radio, RadioChangeEvent } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import styles from './index.module.css';
import { useState } from 'react';
import { FilterOutlined, SyncOutlined } from '@ant-design/icons';
import GlobalDatePicker from '../../../Configurations/Products/globalDatePicker';

type Props = {
    fromDate: Dayjs | null;
    toDate: Dayjs | null;
    onApply: () => void;
    handleSyncShopifyOrder: () => void;
    onSyncInvoice: () => void;
    onSyncPayment: () => void;
    onChangeFromDate: (date: Dayjs | null) => void;
    onChangeToDate: (date: Dayjs | null) => void;
    paymentGatewayMethod: string | null;
    paymentGatewayOnChange: any;
    onSyncOrderTracking: () => void;
    onSyncStock: () => void;
    onSortChange: ({ target: { value } }: RadioChangeEvent) => void;

};


const sortOptions = [
    { label: 'Asc', value: 'asc' },
    { label: 'Desc', value: 'desc' },
];
export const TableActionBar = ({
    fromDate,
    toDate,
    onApply,
    handleSyncShopifyOrder,
    onSyncInvoice,
    onChangeFromDate,
    onChangeToDate,
    onSyncPayment,
    paymentGatewayMethod,
    paymentGatewayOnChange,
    onSyncOrderTracking,
    onSyncStock,
    onSortChange,
}: Props) => {
    const [selectedKey, setSelectedKey] = useState('date');

    const paymentGatewayList = [
        { value: '', label: 'All' },
        { value: 'paypal', label: 'PayPal' },
        { value: 'stripe', label: 'Stripe' },
    ];


    const items = [
        {
            key: 'date',
            label: 'Date',
        },
        // {
        //     key: 'sortBy',
        //     label: 'Sort By',
        // },
        // {
        //     key: 'paymentGatewayMethod',
        //     label: 'Payment Gateway',
        // },
    ];

    const handleMenuClick = (e: any) => {
        setSelectedKey(e.key);
    };

    const handleCancel = () => {
        setSelectedKey('date');
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <Popconfirm
                placement="rightBottom"
                title={false}
                description={
                    <div className={styles['filer-content']}>
                        <Menu
                            mode="vertical"
                            items={items}
                            onClick={handleMenuClick}
                            selectedKeys={[selectedKey]}
                            defaultSelectedKeys={[selectedKey]}
                        />
                        {selectedKey === 'date' && (
                            <div>
                                <GlobalDatePicker
                                    label="From Date"
                                    value={fromDate}
                                    onChange={(date) => onChangeFromDate(date)}
                                    disabledDate={(current) => {
                                        const flag = toDate && current && current > dayjs(toDate);
                                        return Boolean(flag);
                                    }}
                                />
                                <GlobalDatePicker
                                    label="To Date"
                                    value={toDate}
                                    onChange={onChangeToDate}
                                    disabledDate={(current) => {
                                        const flag = fromDate && current && current < dayjs(fromDate);
                                        return Boolean(flag);
                                    }}
                                />
                            </div>
                        )}
                        {selectedKey === 'sortBy' && (
                            <div>
                                <Radio.Group name="createdOrderDate" options={sortOptions} onChange={onSortChange} defaultValue={'desc'} />
                            </div>
                        )}
                        {selectedKey === 'customer' && (
                            <div>
                                <p>Customer information</p>
                            </div>
                        )}
                        {selectedKey === 'syncStatus' && (
                            <div>
                                <p>syncStatus information</p>
                            </div>
                        )}
                        {selectedKey === 'amount' && (
                            <div>
                                <p>amount information</p>
                            </div>
                        )}
                        {selectedKey === 'paymentGatewayMethod' && (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Radio.Group
                                    onChange={paymentGatewayOnChange}
                                    value={paymentGatewayMethod}
                                >
                                    {paymentGatewayList.map((gateway) => (
                                        <Radio
                                            key={gateway.value}
                                            value={gateway.value}
                                            style={{ marginBottom: '10px' }}
                                        >
                                            {gateway.label}
                                        </Radio>
                                    ))}
                                </Radio.Group>
                            </div>
                        )}
                    </div>
                }
                okText="Apply"
                cancelText="Cancel"
                onConfirm={() => onApply && onApply()}
                onCancel={handleCancel}
                icon={false}
            >
                <Button type="primary" size="large" icon={<FilterOutlined />}>
                    Filters
                </Button>
            </Popconfirm>
            <div style={{ display: 'flex', gap: '10px' }}>
                <Button
                    type="primary"
                    size="large"
                    icon={<SyncOutlined />}
                    onClick={() => {
                        handleSyncShopifyOrder();
                    }}
                >
                    Sync Order
                </Button>
                {/* <Button
                    type="primary"
                    size="large"
                    icon={<SyncOutlined />}
                    onClick={() => {
                        onSyncInvoice();
                    }}
                >
                    Sync Invoices
                </Button>
                <Button
                    type="primary"
                    size="large"
                    icon={<SyncOutlined />}
                    onClick={() => {
                        onSyncPayment();
                    }}
                >
                    Sync Payment
                </Button>
                <Button
                    type="primary"
                    size="large"
                    icon={<SyncOutlined />}
                    onClick={() => {
                        onSyncOrderTracking();
                    }}
                >
                    Sync Order Tracking
                </Button>
                <Button
                    type="primary"
                    size="large"
                    icon={<SyncOutlined />}
                    onClick={() => {
                        onSyncStock();
                    }}
                >
                    Sync Stock
                </Button> */}
            </div>
        </div>
    );
};
