import { FieldTimeOutlined } from '@ant-design/icons';
import { Col, Popover, RadioChangeEvent, Row, Table } from 'antd';
import { useEffect, useState } from 'react';
import synclogService from '../../../../client-lib/api/synclog-service';
import { CustomPagination } from '../../../../components/global/table-pagination';
import { toastText } from '../../../../components/global/toast';
import { IPagination, IQuery, Page } from '../../../../lib/types/global-types';
import styles from './index.module.css';
import PopConfirmSyncInfo from './pop-confirm-info';
import { SynclogHistoryModal } from './syncHistory-modal';
import { ISynclog } from './types';
import { io } from 'socket.io-client';
import { Dayjs } from 'dayjs';
import { hasText } from '../../../../lib/utils';
import { TableActionBar } from './action-bar';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';

export const B2BOrders = () => {
    const [loading, setLoading] = useState(true);

    const [openSyncHistoryModal, setOpenSyncHistoryModal] = useState(false);
    const [pagination, setPagination] = useState<IPagination>({
        pageNo: 1,
        pageSize: 10,
    });
    const [page, setPage] = useState<Page<any>>({
        content: [],
        count: 0,
    });
    const [syncHistory, setSyncHistory] = useState([]);
    const [fromDate, setFromDate] = useState<Dayjs | null>(null);
    const [toDate, setToDate] = useState<Dayjs | null>(null);
    const [invoiceStatus, setInvoiceStatus] = useState('all');

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [socket, setSocket] = useState<any>(null);

    useEffect(() => {
        const token = localStorage.getItem('token'); // Replace with your JWT token
        const socket = io(process.env.REACT_APP_WEB_SOCKET_URL as string, {
            query: { token },
            path: '/api/web-socket',
        });

        socket.on('connect', () => {
            console.log('Connected to Socket.IO server');
        });

        socket.on('synced-with-hubspot', (message) => {
            console.log(message);
            getSynclogs(false);
        });

        socket.on('disconnect', () => {
            console.log('Disconnected from Socket.IO server');
        });

        setSocket(socket);

        getSynclogs();

        return () => {
            socket.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getSynclogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination]);

    const handleOk = () => {
        setPagination({
            pageNo: 1,
            pageSize: 10,
        });
    };

    const getSynclogs = async (showLoader: boolean = true) => {
        if (showLoader) {
            setLoading(true);
        }
        try {
            const query: IQuery = {
                ...pagination,
            };

            if (fromDate && toDate) {
                query.fromDate = fromDate.format('YYYY-MM-DD');
                query.toDate = toDate.format('YYYY-MM-DD');
            }

            if (invoiceStatus) {
                query.invoiceStatus = invoiceStatus;
            }
            const res = await synclogService.getSynclogs(query);

            if (res.result) {
                setPage(res.result);
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        }
        setLoading(false);
    };

    const handleSyncNow = async () => {
        setLoading(true);

        try {
            const res = await synclogService.syncNow();

            if (res.result?.success) {
                toastText('Orders syncing started successfully', 'success');
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        }
        setLoading(false);
    };

    const handleSyncInvoice = async () => {
        setLoading(true);

        try {
            const res = await synclogService.syncInvoice();

            if (res.result?.success) {
                toastText('Invoices syncing started successfully', 'success');
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        }
        setLoading(false);
    };

    const handleSyncPayment = async () => {
        setLoading(true);

        try {
            const res = await synclogService.syncPayment();

            if (res.result?.success) {
                toastText('Payment syncing started successfully', 'success');
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        }
        setLoading(false);
    };

    const handleSyncOrderTracking = async () => {
        setLoading(true);

        try {
            const res = await synclogService.syncOrderTracking();

            if (res.result?.success) {
                toastText(
                    'Order tracking syncing started successfully',
                    'success'
                );
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        }
        setLoading(false);
    };

    const handleSyncStock = async () => {
        setLoading(true);

        try {
            const res = await synclogService.syncStock();

            if (res.result?.success) {
                toastText('Stock syncing started successfully', 'success');
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        }
        setLoading(false);
    };

    const getHubSpotStyle = (record: ISynclog) => {
        if (
            (record.hubSpotDealId &&
                record.hubSpotContactId &&
                record.hubSpotQuoteId) ||
            record.hubSpotInvoiceId
        ) {
            return styles['synced'];
        }

        if (
            record.hubSpotDealId ||
            record.hubSpotContactId ||
            record.hubSpotQuoteId
        ) {
            return styles['pending'];
        }

        return styles['unsynced'];
    };

    const getLinnworksStyle = (record: ISynclog) => {
        if (
            record.linnworksOrderId &&
            (record.postalTrackingNumber || record.trackingUrl)
        ) {
            return styles['synced'];
        }

        if (
            record.linnworksOrderId ||
            record.postalTrackingNumber ||
            record.trackingUrl
        ) {
            return styles['pending'];
        }

        return styles['unsynced'];
    };

    const getXeroStyle = (record: ISynclog) => {
        if (
            record.xeroContactId &&
            record.xeroInvoiceId &&
            record.xeroPaymentId
        ) {
            return styles['synced'];
        }

        if (
            record.xeroContactId ||
            record.xeroInvoiceId ||
            record.xeroPaymentId
        ) {
            return styles['pending'];
        }

        return styles['unsynced'];
    };

    const radioOnChange = (e: RadioChangeEvent) => {
        setInvoiceStatus(e.target.value);
    };

    return (
        <div>
            <Row gutter={24}>
                <Col span={24} style={{ marginBottom: '10px' }}>
                    <TableActionBar
                        fromDate={fromDate}
                        toDate={toDate}
                        onApply={handleOk}
                        onSyncNow={handleSyncNow}
                        onSyncInvoice={handleSyncInvoice}
                        onSyncPayment={handleSyncPayment}
                        onChangeFromDate={(date) => setFromDate(date)}
                        onChangeToDate={(date) => setToDate(date)}
                        invoiceStatusOnChange={radioOnChange}
                        invoiceStatus={invoiceStatus}
                        onSyncOrderTracking={handleSyncOrderTracking}
                        onSyncStock={handleSyncStock}
                    />
                </Col>
                <Col
                    span={24}
                    style={{
                        height: 'calc(100vh - 249.48px)',
                    }}
                >
                    <Table
                        pagination={false}
                        loading={loading}
                        dataSource={page.content}
                        scroll={{
                            y: 'calc(100vh - 322px)',
                        }}
                        rowKey={(data: ISynclog) => {
                            return data.id;
                        }}
                        columns={[
                            {
                                title: 'Created Date',
                                dataIndex: 'createdAt',
                                key: 'createdAt',
                                width: 160,
                                render: (value: string) => {
                                    return hasText(value)
                                        ? moment(value).format('MM/DD/YYYY')
                                        : '-';
                                },
                            },
                            {
                                title: 'Updated Date',
                                dataIndex: 'updatedAt',
                                key: 'updatedAt',
                                width: 160,
                                render: (value: string) => {
                                    return hasText(value)
                                        ? moment(value).format('MM/DD/YYYY')
                                        : '-';
                                },
                            },
                            {
                                title: 'Customer',
                                dataIndex: 'customer',
                                key: 'customer',
                                render: (value: string) => {
                                    return hasText(value) ? value : '-';
                                },
                            },
                            {
                                title: 'Amount',
                                dataIndex: 'amount',
                                key: 'amount',
                                render: (value: string) => {
                                    return (
                                        <NumericFormat
                                            value={value}
                                            thousandSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            displayType="text"
                                            prefix="£"
                                            renderText={(value) => (
                                                <p>{value ?? 0}</p>
                                            )}
                                        />
                                    );
                                },
                            },
                            {
                                title: 'Invoice',
                                dataIndex: 'hubSpotInvoiceNumber',
                                key: 'hubSpotInvoiceNumber',
                                render: (_, record: any) => {
                                    return hasText(
                                        record.hubSpotInvoiceNumber
                                    ) ? (
                                        <a
                                            href={record.hubspotInvoiceUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {record.hubSpotInvoiceNumber}
                                        </a>
                                    ) : (
                                        '-'
                                    );
                                },
                            },

                            {
                                title: 'Invoice Status',
                                dataIndex: 'status',
                                key: 'status',
                                render: (value: string) => {
                                    return hasText(value) ? value : '-';
                                },
                            },
                            {
                                title: '',
                                dataIndex: 'synclog-status',
                                key: 'synclog-status',
                                width: 450,
                                render: (_, record: ISynclog) => {
                                    return (
                                        <div className={styles['sync-status']}>
                                            <Popover
                                                content={
                                                    <PopConfirmSyncInfo
                                                        title="hubspot"
                                                        invoiceData={record}
                                                    />
                                                }
                                                title={false}
                                            >
                                                <div
                                                    className={`${
                                                        styles['arrow']
                                                    } ${getHubSpotStyle(
                                                        record
                                                    )}`}
                                                >
                                                    Hubspot
                                                </div>
                                            </Popover>

                                            <Popover
                                                content={
                                                    <PopConfirmSyncInfo
                                                        title="linnworks"
                                                        invoiceData={record}
                                                    />
                                                }
                                                title={false}
                                            >
                                                <div
                                                    className={`${
                                                        styles['arrow']
                                                    } ${getLinnworksStyle(
                                                        record
                                                    )}`}
                                                >
                                                    Linnworks
                                                </div>
                                            </Popover>
                                            <Popover
                                                content={
                                                    <PopConfirmSyncInfo
                                                        title="xero"
                                                        invoiceData={record}
                                                    />
                                                }
                                                title={false}
                                            >
                                                <div
                                                    className={`${
                                                        styles['arrow']
                                                    } ${getXeroStyle(record)}`}
                                                >
                                                    Xero
                                                </div>
                                            </Popover>
                                        </div>
                                    );
                                },
                            },
                            {
                                title: '',
                                dataIndex: 'syncHistory',
                                key: 'syncHistory',
                                width: 150,
                                render: (_, record: ISynclog) => {
                                    return (
                                        <div
                                            className={`${
                                                record.syncLog.syncHistory
                                                    ? styles['syncHistory']
                                                    : styles['notSyncHistory']
                                            }`}
                                            onClick={() => {
                                                if (
                                                    record.syncLog.syncHistory
                                                ) {
                                                    setOpenSyncHistoryModal(
                                                        true
                                                    );
                                                    setSyncHistory(
                                                        record.syncLog
                                                            .syncHistory
                                                    );
                                                }
                                            }}
                                        >
                                            <FieldTimeOutlined
                                                style={{ fontSize: '18px' }}
                                            />
                                            <p>Sync History</p>
                                        </div>
                                    );
                                },
                            },
                        ]}
                    />
                </Col>
                {page.count ? (
                    <Col span={24} style={{ marginTop: '10px' }}>
                        <CustomPagination
                            pagination={pagination}
                            counts={page.count}
                            onChangePagination={(data: IPagination) => {
                                setPagination({ ...data });
                            }}
                        />
                    </Col>
                ) : null}
            </Row>
            {openSyncHistoryModal ? (
                <SynclogHistoryModal
                    open={openSyncHistoryModal}
                    syncHistory={syncHistory}
                    onCancel={() => {
                        setOpenSyncHistoryModal(false);
                    }}
                />
            ) : null}
        </div>
    );
};
