import { SyncOutlined } from '@ant-design/icons';
import { Button, Col, Row, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import synclogService from '../../../../client-lib/api/synclog-service';
import { toastText } from '../../../../components/global/toast';
import { hasText } from '../../../../lib/utils';
import { CustomPagination } from '../../../../components/global/table-pagination';
import { IPagination, Page } from '../../../../lib/types/global-types';
import { NumericFormat } from 'react-number-format';

export const COGSSynclogs = () => {
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState<IPagination>({
        pageNo: 1,
        pageSize: 10,
    });
    const [page, setPage] = useState<Page<any>>({
        content: [],
        count: 0,
    });
    const getCOGS = async () => {
        setLoading(true);
        try {
            const response = await synclogService.getCOGS();
            if (response.result.content) {
                setPage(response.result);
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        }
        setLoading(false);
    };
    const handleSyncJournals = async () => {
        setLoading(true);

        try {
            const res = await synclogService.syncJournals();

            if (res.result?.success) {
                toastText('Journals syncing started successfully', 'success');
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        }
        setLoading(false);
    };

    useEffect(() => {
        getCOGS();
    }, []);
    useEffect(() => {
        setPagination({
            pageNo: 1,
            pageSize: 10,
        });
    }, []);

    return (
        <div>
            <Row gutter={24}>
                <Col span={24} style={{ marginBottom: '10px' }}>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <Button
                            type="primary"
                            size="large"
                            icon={<SyncOutlined />}
                            onClick={() => {
                                handleSyncJournals();
                            }}
                        >
                            Sync Journals
                        </Button>
                    </div>
                </Col>
                <Col
                    span={24}
                    style={{
                        height: 'calc(100vh - 249.48px)',
                    }}
                >
                    <Table
                        pagination={false}
                        dataSource={page.content}
                        loading={loading}
                        scroll={{
                            y: 'calc(100vh - 322px)',
                        }}
                        rowKey={(data: any) => {
                            return data.id;
                        }}
                        columns={[
                            {
                                title: 'Date',
                                dataIndex: 'createdAt',
                                key: 'createdAt',
                                width: 200,
                                render: (value: string) => {
                                    return hasText(value)
                                        ? moment(value).format('MM/DD/YYYY')
                                        : '-';
                                },
                            },
                            {
                                title: 'Journal Number',
                                dataIndex: 'journalNo',
                                key: 'journalNo',
                                width: 200,

                                render: (_, data: any) => {
                                    return (
                                        <a
                                            href={data.cogsNumberUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {data.journalNo
                                                ? data.journalNo
                                                : '-'}
                                        </a>
                                    );
                                },
                            },
                            {
                                title: 'COGS Amount',
                                dataIndex: 'cogsAmount',
                                key: 'cogsAmount',
                                width: 200,

                                render: (value: string) => {
                                    return (
                                        <NumericFormat
                                            value={value}
                                            thousandSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            displayType="text"
                                            prefix="£"
                                            renderText={(value) => (
                                                <p>{value ?? 0}</p>
                                            )}
                                        />
                                    );
                                },
                            },
                        ]}
                    />
                </Col>
                {/* {page.count ? (
                    <Col span={24} style={{ marginTop: '10px' }}>
                        <CustomPagination
                            pagination={pagination}
                            counts={page.count}
                            onChangePagination={(data: IPagination) => {
                                setPagination({ ...data });
                            }}
                        />
                    </Col>
                ) : null} */}
            </Row>
        </div>
    );
};
