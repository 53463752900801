export function isBlank(value?: string | number | null): boolean {
    return (
        null === value ||
        undefined === value ||
        value.toString().trim().length === 0
    );
}

export function isNullOrUndefined(value: any): boolean {
    return null === value || undefined === value;
}

export function hasText(value?: string | null | undefined | number): boolean {
    return !isBlank(value);
}

const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
};

export function validateFormData(
    dataObj: { [key: string]: any },
    errObj: { [key: string]: boolean }
): { [key: string]: boolean } {
    Object.keys(dataObj).forEach(key => {
        if (Object.hasOwn(errObj, key)) {
            errObj[key] = !hasText(dataObj[key]);
            if (key === 'email' || key === 'secondaryEmail') {
                errObj[key] = !validateEmail(dataObj[key]);
            }
        }
    });

    return errObj;
}

export function hasFormError(errObj: { [key: string]: boolean }): boolean {
    return Object.keys(errObj).some(key => {
        return errObj[key];
    });
}

function urlToFile(url: string, filename: string, mimeType: any) {
    return fetch(url)
        .then(function (res) {
            return res.arrayBuffer();
        })
        .then(function (buf) {
            return new File([buf], filename, { type: mimeType });
        });
}

export default async function resizeImage(file: File) {
    // eslint-disable-next-line no-undef
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
            const img: any = document.createElement('img');
            img.src = reader.result;
            img.setAttribute(
                'style',
                'display: none; position: absolute; z-index: 999999999!important'
            );
            document.body.appendChild(img);

            // eslint-disable-next-line no-undef
            await new Promise<void>(resolve => {
                const interval = setInterval(() => {
                    if (img.width) {
                        clearInterval(interval);
                        resolve();
                    }
                }, 50);
            });

            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx: any = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, img.width, img.height);
            const webpImage = canvas.toDataURL('image/webp', 0.6);
            resolve(urlToFile(webpImage, file.name, 'image/webp'));
        };
    });
}

export function validatePassword(password: string) {
    const pattern = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    const passwordRegex = new RegExp(pattern);

    return passwordRegex.test(password);
}

export function validateZipCode(value: string) {
    const regex = new RegExp(
        /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z] ?\d[ABCEGHJ-NPRSTV-Z]\d$/
    );
    return regex.test(value.trim());
}

export function keepOnlyNumbers(str: string) {
    return str.replace(/[^0-9]/g, '');
}
export function fullTrim(str: string): string {
    let trimmedStr = str.trim();

    trimmedStr = trimmedStr.replace(/\s\s+/g, ' ');

    return trimmedStr;
}


export function formatNumberWithCommasV2(number: any) {
    return Number(number)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}