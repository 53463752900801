// notificationHelper.tsx
import { CheckCircleOutlined, CloseCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { notification } from 'antd';

export const openNotification = (type: 'success' | 'error' | 'warning', message: string, description: string) => {
    let icon;
    switch (type) {
        case 'success':
            icon = <CheckCircleOutlined style={{ color: 'green' }} />;
            break;
        case 'error':
            icon = <CloseCircleOutlined style={{ color: 'red' }} />;
            break;
        case 'warning':
            icon = <WarningOutlined style={{ color: 'yellow' }} />;
            break;
    }

    notification.open({
        message,
        description,
        icon,
    });
};