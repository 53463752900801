import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons';
import { Modal, Timeline } from 'antd';
import './index.css';
import { ISynclogHistory } from './types';

type Props = {
    open: boolean;
    onCancel: (isEdit?: boolean) => void;
    syncHistory: ISynclogHistory[];
};

export const SynclogHistoryModal = ({ open, onCancel, syncHistory }: Props) => {
    const transformSyncHistory = (history: ISynclogHistory[]) => {
        return history?.map((data: ISynclogHistory) => ({
            label: new Date(data.timeStamp).toLocaleString(),
            children: <p>{data.message}</p>,
            dot:
                data.status === 'success' ? (
                    <CheckCircleOutlined
                        style={{ fontSize: '16px', color: 'green' }}
                    />
                ) : data.status === 'pending' ? (
                    <InfoCircleOutlined
                        style={{ fontSize: '16px', color: 'orange' }}
                    />
                ) : (
                    <CloseCircleOutlined
                        style={{ fontSize: '16px', color: 'red' }}
                    />
                ),
        }));
    };

    return (
        <Modal
            open={open}
            footer={null}
            width={800}
            onCancel={() => onCancel()}
            title={'Sync History'}
            centered
            className="sync-history-model"
        >
            <Timeline
                mode={'left'}
                items={transformSyncHistory(syncHistory)}
                className="syncHistoryItem"
            />
        </Modal>
    );
};
