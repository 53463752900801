import { Button, Col, Input, Row } from 'antd';
import styles from './index.module.css';
import { useState } from 'react';
import { fullTrim, hasText } from '../../lib/utils';
import authService from '../../client-lib/api/auth-service';
import { Link, useNavigate } from 'react-router-dom';
import { toastText } from '../../components/global/toast';

export const Login = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const [showError, setShowError] = useState(false);

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!hasText(email) || !hasText(password)) {
            setShowError(true);
            return;
        }

        setLoading(true);
        try {
            const res = await authService.login({
                email,
                password,
            });

            if (res?.result?.token) {
                localStorage.setItem('token', res.result.token);
                navigate('/');
                toastText('Successfully logged in', 'success');
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        }
        setLoading(false);
    };

    return (
        <div className={styles['login-container']}>
            <div className={styles['login-img']}>
                <img
                    src="/crystal-clear-logo.webp"
                    alt="Crystal Clear"
                    height={55}
                />
            </div>
            <form onSubmit={handleSubmit}>
                <Row gutter={24}>
                    <Col span={24} className="mt-20">
                        <Input
                            status={showError && !hasText(email) ? 'error' : ''}
                            placeholder="Email"
                            style={{
                                width: '100%',
                            }}
                            size="large"
                            type="email"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            onBlur={(e) => setEmail(fullTrim(e.target.value))}
                        />
                        {showError && !hasText(email) ? (
                            <p style={{ color: '#ff4d4f' }}>
                                {' '}
                                Please input your email
                            </p>
                        ) : null}
                    </Col>
                    <Col span={24} className="mt-20">
                        <Input.Password
                            status={
                                showError && !hasText(password) ? 'error' : ''
                            }
                            placeholder="Password"
                            style={{
                                width: '100%',
                            }}
                            size="large"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            onBlur={(e) =>
                                setPassword(fullTrim(e.target.value))
                            }
                        />
                        {showError && !hasText(password) ? (
                            <p style={{ color: '#ff4d4f' }}>
                                Please input your password
                            </p>
                        ) : null}
                    </Col>
                    <Col span={24} className="mt-20">
                        <Link
                            to={'/forgot-password'}
                            style={{ textAlign: 'right' }}
                        >
                            <p>Forgot Password?</p>
                        </Link>
                    </Col>
                    <Col span={24} className="mt-20">
                        <Button
                            htmlType="submit"
                            size="large"
                            style={{
                                width: '100%',
                            }}
                            type="primary"
                            loading={loading}
                        >
                            Login
                        </Button>
                    </Col>
                </Row>
            </form>
        </div>
    );
};
