import { ExportOutlined } from '@ant-design/icons';
import { Modal, Table } from 'antd';
import { useEffect, useState } from 'react';
import synclogService from '../../../../client-lib/api/synclog-service';
import { hasText } from '../../../../lib/utils';

type Props = {
    open: boolean;
    onCancel: (isEdit?: boolean) => void;
    date: string;
    paymentGateway: string;
};


export const LinnworksSyncModal = ({ open, onCancel, date, paymentGateway }: Props) => {
    const [loading, setLoading] = useState(true);
    const [ordersData, setOrdersData] = useState([{ key: '', shopifyOrderId: '', linnworksOrderUrl: '' }]);

    useEffect(() => {
        const fetchData = async () => {
            if (open) {
                setLoading(true);
                try {
                    const response = await synclogService.getOrdersByPaymentGatewayAndDate({ date, paymentGateway });
                    setOrdersData(response.result || [])
                } catch (error) {
                    console.error('Failed to fetch data:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchData();
    }, [open, date, paymentGateway]);

    const columns = [
        {
            title: '#Shopify',
            dataIndex: 'orderId',
            key: 'orderId',
            render: (text: string, record: any) => {
                return hasText(text)
                    ? <a href={`${record.shopifyUrl}`} target='_blank' rel='noopener noreferrer'>
                        {'Order ' + text} <ExportOutlined />
                    </a>
                    : '-';
            },
        },
        {
            title: '#Linnworks',
            dataIndex: 'linnworksOrderUrl',
            key: 'linnworksOrderUrl',
            render: (text: string, record: any) => {
                return hasText(record.linnworksOrderNum) ? (
                    <a
                        href={`${record.linnworksOrderUrl}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {'Order #' + record.linnworksOrderNum}{' '}
                        <ExportOutlined />
                    </a>
                ) : (
                    '-'
                );
            },
        },
    ];

    return (
        <Modal
            open={open}
            footer={null}
            width={1000}
            style={{
                height: '80%',
                // position: 'sticky'
            }}
            onCancel={() => onCancel()}
            title={'Shopify to Linnworks'}
            centered
            className='linnworks-model'
        >
            <Table
                loading={loading}
                pagination={false}
                scroll={{ y: 450 }}
                columns={columns}
                dataSource={ordersData}
                rowKey={(record) => record.key}
            />
        </Modal>
    );
};
