import { Col, Form, Input, Modal, Row, Image, Button, Tag } from 'antd';
import { useState } from 'react';
import { toastText } from '../toast';
import connectionService from '../../../client-lib/api/connection-service';
import styles from './index.module.css';
import { ExportOutlined } from '@ant-design/icons';

type Props = {
    open: boolean;
    onClose: () => void;
};
const LinnworksModal = ({ open, onClose }: Props) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (values: any): Promise<void> => {
        try {
            setIsLoading(true);
            const data = {
                token: values.token,
            };
            const response = await connectionService.linnworksAuth(data);
            if (response.result?.success) {
                toastText('Linnworks connected successfully', 'success');
            }
            handleCancel();
        } catch (error: any) {
            toastText(error.message, 'error');
        } finally {
            setIsLoading(false);
        }
    };
    const handleCancel = () => {
        onClose();
    };
    return (
        <Modal
            open={open}
            onOk={handleSubmit}
            onCancel={handleCancel}
            okText={'Connect'}
            footer={null}
            closable={false}
            className="apiKey_popup"
        >
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                onFinish={handleSubmit}
                autoComplete="off"
            >
                <div className="center">
                    <Image
                        className={styles['image_linnworks']}
                        src="../linnworks-logo.png"
                        preview={false}
                    />
                </div>
                <div>
                    <Row gutter={24}>
                        <Col
                            className="gutter-row fields"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                        >
                            {
                                <>
                                    <div className={styles['form-label']}>
                                        <label>
                                            Token
                                            <span className="required-color">
                                                *
                                            </span>
                                        </label>
                                    </div>

                                    <Form.Item
                                        name="token"
                                        wrapperCol={{ span: 24 }}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please input your token',
                                            },
                                        ]}
                                        style={{ marginBottom: '10px' }}
                                    >
                                        <Input
                                            maxLength={150}
                                            size="large"
                                            type="text"
                                        />
                                    </Form.Item>
                                    <div>
                                        <Tag
                                            style={{
                                                width: '100%',
                                                fontSize: '14px',
                                                padding: '10px 5px',
                                                color: '#0958d9',
                                                background: '#b0bcc5',
                                                borderColor: ' #838485',
                                            }}
                                            className={styles['token-url']}
                                            color="blue"
                                        >
                                            <a
                                                href={
                                                    process.env
                                                        .REACT_APP_LINNWORKS_URL
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Click here to get token
                                                <ExportOutlined
                                                    style={{
                                                        marginLeft: '10px',
                                                    }}
                                                />
                                            </a>
                                        </Tag>
                                    </div>
                                </>
                            }
                        </Col>
                    </Row>
                </div>
                <div className={styles['modal_footer']}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        loading={isLoading}
                    >
                        Connect
                    </Button>
                    <Button
                        size="large"
                        htmlType="button"
                        onClick={() => handleCancel()}
                    >
                        Cancel
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};
export default LinnworksModal;
