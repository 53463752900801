import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import styles from './index.module.css';
import React from 'react';
type Props = {
    title: string;
    invoiceData: any;
};
const PopConfirmSyncInfo = ({ title, invoiceData }: Props) => {
    return (
        <div style={{ width: '15rem' }}>
            {title === 'hubspot' && (
                <div>
                    <div>
                        <p>
                            WooCommerece Order :{' '}
                            {invoiceData.wooCommerceOrderId
                                ? invoiceData.wooCommerceOrderId
                                : '-'}
                        </p>
                    </div>
                    <div>
                        Hubspot Deal :{' '}
                        <a
                            href={invoiceData.hubspotDealUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {invoiceData.hubSpotDealId
                                ? invoiceData.hubSpotDealId
                                : '-'}
                        </a>
                    </div>
                    <div>
                        Hubspot Contact :{' '}
                        <a
                            href={invoiceData.hubspotContactUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {invoiceData.hubSpotContactId
                                ? invoiceData.hubSpotContactId
                                : '-'}
                        </a>
                    </div>
                    <div>
                        Hubspot Quote :{' '}
                        <a
                            href={invoiceData.hubspotQuoteUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {invoiceData.hubSpotQuoteId
                                ? invoiceData.hubSpotQuoteId
                                : '-'}
                        </a>
                    </div>
                    <div>
                        Hubspot Invoice :{' '}
                        <a
                            href={invoiceData.hubspotInvoiceUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {invoiceData.hubSpotInvoiceId
                                ? invoiceData.hubSpotInvoiceId
                                : '-'}
                        </a>
                    </div>
                </div>
            )}
            {title === 'linnworks' && (
                <div>
                    <div className={styles['content-center']}>
                        <p>
                            Order Synced:{' '}
                            <a
                                href={invoiceData.linnworksOrderUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {invoiceData.linnworksOrderNum
                                    ? invoiceData.linnworksOrderNum
                                    : '-'}
                            </a>
                        </p>

                        <div>
                            {invoiceData.linnworksOrderId ? (
                                <CheckCircleOutlined
                                    style={{ color: 'green' }}
                                />
                            ) : (
                                <CloseCircleOutlined style={{ color: 'red' }} />
                            )}
                        </div>
                    </div>
                    <div className={styles['content-center']}>
                        <p>
                            The tracking number and URL to be updated in web app
                        </p>
                        <div>
                            {invoiceData.postalTrackingNumber ||
                            invoiceData.trackingUrl ? (
                                <CheckCircleOutlined
                                    style={{ color: 'green' }}
                                />
                            ) : (
                                <CloseCircleOutlined style={{ color: 'red' }} />
                            )}
                        </div>
                    </div>
                    <div className={styles['content-center']}>
                        <div>
                            {invoiceData.postalTrackingNumber ? (
                                <p>
                                    Tracking number:{' '}
                                    {invoiceData.postalTrackingNumber}
                                </p>
                            ) : null}
                        </div>
                    </div>
                </div>
            )}
            {title === 'xero' && (
                <div>
                    <div>
                        <p>
                            WooCommerece Order :{' '}
                            {invoiceData.wooCommerceOrderId
                                ? invoiceData.wooCommerceOrderId
                                : '-'}
                        </p>
                    </div>
                    <div>
                        Xero Contact :{' '}
                        <a
                            href={invoiceData.xeroContactUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {invoiceData.xeroContactId
                                ? invoiceData.xeroContactId
                                : '-'}
                        </a>
                    </div>
                    <div>
                        Xero Invoice :{' '}
                        <a
                            href={invoiceData.xeroInvoiceUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {invoiceData.xeroInvoiceId ||
                            invoiceData.xeroInvoiceNumber
                                ? invoiceData.xeroInvoiceNumber ||
                                  invoiceData.xeroInvoiceId
                                : '-'}
                        </a>
                    </div>
                    <div>
                        Xero Payment :{' '}
                        <a
                            href={invoiceData.xeroPaymentUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {invoiceData.xeroPaymentId
                                ? invoiceData.xeroPaymentId
                                : '-'}
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PopConfirmSyncInfo;
