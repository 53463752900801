import { ExpandOutlined, MehOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Spin, Tooltip } from 'antd';
import Chart from 'chart.js/auto';
import { useEffect, useState } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import dashboardService from '../../client-lib/api/dashboard-service';
import { formatNumberWithCommasV2 } from '../../lib/utils';
import GraphModal from './graph-modal';
import './index.css';

export const Home = () => {

    const [orderCounts, setOrdersCount] = useState<any>({});
    const [shopifySales, setShopifySales] = useState<any>(null);
     const [products, setProducts] = useState<any>(null);
    const [sales, setSales] = useState<any>(null);
    const [isSalesLoading, setIsSalesLoading] = useState(false);
    const [isProductLoading, setIsProductLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [chartType, setChartType] = useState('');

    const currentYear = new Date().getFullYear();
    const currentMonthIndex = new Date().getMonth();

    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    const items: MenuProps['items'] = months.map((month, index) => ({
        label: <p>{`${month} ${currentYear}`}</p>,
        key: `${month} ${currentYear}`,
    }));

    const [selectedOrdersMonth, setSelectedOrdersMonth] = useState(
        `${months[currentMonthIndex]} ${currentYear}`
    );
    const [selectedB2CMonth, setSelectedB2CMonth] = useState(
        `${months[currentMonthIndex]} ${currentYear}`
    );

    const handleOrdersMonthChange = (event: any) => {
        setSelectedOrdersMonth(event.key);
         getorderCounts(event.key);
    };

    const handleB2CMonthChange = (event: any) => {
        setSelectedB2CMonth(event.key);
        getProduct(event.key);
    };

    const handleClose = () => {
        setIsModalOpen(false);
        setChartType('');
    };

    const handleOpen = () => {
        setIsModalOpen(true);
    };

    useEffect(() => {
        Chart.register(...require('chart.js'));
        getorderCounts(selectedOrdersMonth)
        getProduct(selectedB2CMonth);
        getSales();
        getB2BSales();
    }, []);
  
    const getorderCounts = async (_selectedOrdersMonth: string) => {
        setOrdersCount({})
        try {
            setIsProductLoading(true);
            const query = {
                month: months.indexOf(_selectedOrdersMonth.split(' ')[0]) + 1,
            };
            const response = await dashboardService.getOrderCountsAndPercentages(query);
            setOrdersCount(response.result);

        } catch (error) {
            console.log('Error fetching products:', error);
        } finally {
            setIsProductLoading(false);
        }
    };

    // Function to fetch products data
    const getProduct = async (_selectedB2CMonth: string) => {
        try {
            setIsProductLoading(true);
            const query = {
                month: months.indexOf(_selectedB2CMonth.split(' ')[0]) + 1,
            };
            const response = await dashboardService.getProducts(query);
            setProducts(response.result);
        } catch (error) {
            console.log('Error fetching products:', error);
        } finally {
            setIsProductLoading(false);
        }
    };

    // Function to fetch sales data
    const getSales = async () => {
        try {
            setIsSalesLoading(true);
            const response = await dashboardService.getSales();
            if (response.result) {
                setSales(response.result);
            }
        } catch (error) {
            console.log('Error fetching sales:', error);
        } finally {
            setIsSalesLoading(false);
        }
    };

    const getB2BSales = async () => {
        try {
            setIsSalesLoading(true);
            const response = await dashboardService.getShopifySales()
            if (response.result) {
                setShopifySales(response.result);
            }
        } catch (error) {
            console.log('Error fetching sales:', error);
        } finally {
            setIsSalesLoading(false);
        }
    };


    // Prepare data for Pie chart based on fetched products
    const preparePieChartData = (topFive: boolean) => {
        if (!products) {
            return {
                labels: [],
                datasets: [
                    { data: [], backgroundColor: [], hoverBackgroundColor: [] },
                ],
            };
        }
        const labels = topFive
            ? products.map((product: any) => product.productName).slice(0, 5)
            : products.map((product: any) => product.productName);
        const data = products.map((product: any) =>
            parseFloat(product.percentage)
        );
        const backgroundColors = [
            '#36A2EB',
            '#FF6384',
            '#FFCE56',
            '#4BC0C0',
            '#9966FF',
            '#FF9F40',
            '#FFCD56',
            '#C9CBCF',
            '#B2DF8A',
        ];

        const hoverBackgroundColors = backgroundColors.slice(); // Copy of backgroundColors array

        return {
            labels,
            datasets: [
                {
                    data,
                    backgroundColor: backgroundColors,
                    hoverBackgroundColor: hoverBackgroundColors,
                },
            ],
        };
    };

    // Prepare data for Bar chart based on fetched sales
    const prepareBarChartData = () => {
        if (!sales || !shopifySales)
            return {
                labels: [],
                datasets: [
                    {
                        label: 'B2B Sales',
                        data: [],
                        backgroundColor: '#6c8289',
                        borderColor: 'rgba(255, 255, 255, 1)',
                        borderWidth: 1,
                        barThickness: 40,
                        maxBarThickness: 40,
                        color: '#6c8289',
                    },
                    {
                        label: 'B2C Sales',
                        data: [],
                        backgroundColor: '#3498db',
                        borderColor: 'rgba(255, 255, 255, 1)',
                        borderWidth: 1,
                        barThickness: 40,
                        maxBarThickness: 40,
                        color: '#3498db',
                    },
                ],
            };

        const labels = sales.map((sale: any) => sale.month);
        const B2BSalesData = sales.map((sale: any) => sale.totalsales);
        const B2CSalesData = shopifySales.map((sale: any) => sale.totalsales);
        return {
            labels,
            datasets: [
                {
                    label: 'B2B Sales',
                    data: B2BSalesData,
                    backgroundColor: '#6c8289',
                    borderColor: 'rgba(255, 255, 255, 1)',
                    borderWidth: 1,
                    barThickness: 40,
                    maxBarThickness: 40,
                    color: '#6c8289',
                },
                {
                    label: 'B2C Sales',
                    data: B2CSalesData,
                    backgroundColor: '#3498db',
                    borderColor: 'rgba(255, 255, 255, 1)',
                    borderWidth: 1,
                    barThickness: 40,
                    maxBarThickness: 40,
                    color: '#3498db',
                },
            ],
        };
    };

    const B2CBackgroundColors = ['#6c828a', '#3498db']
    const ordersData = {
        labels: ['B2B', 'B2C'],
        datasets: [
            {
                data: [orderCounts?.orderPercentage | 0, orderCounts?.salesPercentage | 0],
                backgroundColor: B2CBackgroundColors,
                hoverBackgroundColors: B2CBackgroundColors
            },
        ],
    };
    const pieOption: any = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'bottom',
                labels: {
                    boxWidth: 10,
                    color: 'black',
                },
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: (tooltipItem: any) => {
                        return `${formatNumberWithCommasV2(tooltipItem.raw)}%`;
                    },
                },
            },
        },
    };

    const barOption: any = {
        plugins: {
            title: {
                display: true,
                color: 'black',
                font: {
                    size: 16,
                },
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: (tooltipItem: any) => {
                        return `£${formatNumberWithCommasV2(tooltipItem.raw)}`;
                    },
                },
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Sales',
                    color: 'black',
                },
                ticks: {
                    callback: (tickValue: any) => {
                        return `£${formatNumberWithCommasV2(tickValue)}`;
                    },
                    color: 'black',
                },
                grid: {
                    display: false,
                },
            },
            x: {
                title: {
                    display: true,
                    text: 'Month',
                    color: 'black',
                },
                ticks: {
                    color: 'black',
                },
                grid: {
                    display: false,
                },
            },
        },
    };
    return (
        <div className="container">
            <div className="cardContainer">
                <div className="card">
                    <div className="cardHeader">
                        <h3>Total orders for month of {selectedOrdersMonth}</h3>
                        <div className="centerData">
                            <Dropdown
                                placement="bottom"
                                menu={{
                                    items,
                                    onClick: handleOrdersMonthChange,
                                }}
                            >
                                <Button style={{ width: '7rem' }}>
                                    {selectedOrdersMonth}
                                </Button>
                            </Dropdown>
                            <Tooltip
                                placement="top"
                                title="View detailed chart"
                            >
                                <ExpandOutlined
                                    onClick={() => {
                                        if (sales.length) {
                                            handleOpen();
                                        }
                                    }}
                                />
                            </Tooltip>
                        </div>
                    </div>
                    <div
                        className="cardBody"
                        onClick={() => {
                            if (sales.length) {
                                handleOpen();
                            }
                            setChartType('ordersData');
                        }}
                    >
                        {orderCounts ? (
                            <Pie data={ordersData} options={pieOption} />
                        ) : (
                            <div className="centerData">
                                <p
                                    style={{
                                        color: '#6c8289',
                                    }}
                                >
                                    Oops no data available
                                </p>
                                <MehOutlined
                                    style={{
                                        fontSize: '20px',
                                        color: '#6c8289',
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="card">
                    <div className="cardHeader">
                        <h3>
                            Total products sold for month of {selectedB2CMonth}
                        </h3>
                        <div className="centerData">
                            <Dropdown
                                placement="bottom"
                                menu={{
                                    items,
                                    onClick: handleB2CMonthChange,
                                }}
                            >
                                <Button style={{ width: '7rem' }}>
                                    {selectedB2CMonth}
                                </Button>
                            </Dropdown>
                            <Tooltip
                                placement="top"
                                title="View detailed chart"
                            >
                                <ExpandOutlined
                                    onClick={() => {
                                        if (products.length) {
                                            handleOpen();
                                        }
                                        setChartType('productData');
                                    }}
                                />
                            </Tooltip>
                        </div>
                    </div>
                    <div
                        className="cardBody"
                        onClick={() => {
                            if (products.length) {
                                handleOpen();
                            }
                            setChartType('productData');
                        }}
                    >
                        {products?.length ? (
                            <Pie
                                data={preparePieChartData(false)}
                                options={pieOption}
                            />
                        ) : (
                            <div className="centerData">
                                <p
                                    style={{
                                        color: '#6c8289',
                                    }}
                                >
                                    Oops no data available
                                </p>
                                <MehOutlined
                                    style={{
                                        fontSize: '20px',
                                        color: '#6c8289',
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="chartContainer">
                <h3>Last six months sales</h3>
                {isSalesLoading ? (
                    <Spin spinning={isSalesLoading} />
                ) : (
                    <Bar
                        data={prepareBarChartData()}
                        options={barOption}
                        style={{ maxWidth: '100%', maxHeight: '500px' }}
                    />
                )}
            </div>
            {isModalOpen && (
                <GraphModal
                    handleCancel={handleClose}
                    isModalOpen={isModalOpen}
                    chartData={
                        chartType === 'productData'
                            ? preparePieChartData(false)
                            : ordersData
                    }
                    displayTitle={
                        chartType === 'productData'
                            ? `Total products sold for month of ${selectedB2CMonth}`
                            : `Total orders for month of ${selectedOrdersMonth}`
                    }
                />
            )}
        </div>
    );
};

export default Home;
