import { IQuery, Page, Result } from '../../lib/types/global-types';
import http from '../http';

const BASE_PATH = process.env.REACT_APP_API_ENDPOINT;

const getShopifyProducts = async (query: IQuery): Promise<Result<Page<any>>> => {
    return http.get(`${BASE_PATH}/v1/configuration/shopifyProducts`, {
        queryParams: query,
    });
};

const syncShopifyProducts = async () => {
    return http.get(`${BASE_PATH}/v1/invoke/shopify/products`);
};

const getXeroAccount = async () => {
    return http.get(`${BASE_PATH}/v1/configuration/xero/accounts`);
};

const getTaxType = async () => {
    return http.get(`${BASE_PATH}/v1/configuration/xero/taxType`);
};

const updateProduct = async (data: any) => {
    return http.put(`${BASE_PATH}/v1/configuration/shopify/products`, {
        body: data,
    });
};

const getPaymentGateways = async (data: any) => {
    return http.get(`${BASE_PATH}/v1/configuration/paymentGateways`);
};

const updatePaymentGateways = async (data: any) => {
    return http.put(`${BASE_PATH}/v1/configuration/paymentGateways/update`, {
        body: data,
    });
};

const syncXeroAccounts = async () => {
    return http.get(`${BASE_PATH}/v1/invoke/xero/accounts`);
};

const configurationService = {
    getShopifyProducts,
    syncShopifyProducts,
    getXeroAccount,
    getTaxType,
    updateProduct,
    syncXeroAccounts,
    getPaymentGateways,
    updatePaymentGateways,
};

export default configurationService;
