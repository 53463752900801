import { Col, Form, Input, Modal, Row, Image, Button } from 'antd';
import { useState } from 'react';

import styles from './index.module.css';
import { toastText } from '../toast';
import connectionService from '../../../client-lib/api/connection-service';
import { WooCommerceFields } from '../../../lib/constants/data';
import { IWooFields } from './types';

type Props = {
    authModalVisible: boolean;
    onClose: () => void;
};
const WooCommerceAuthModal = ({ authModalVisible, onClose }: Props) => {
    const [isWooConnectionLoading, setIsWooConnectionLoading] = useState(false);

    const handleSubmit = async (values: any): Promise<void> => {
        try {
            setIsWooConnectionLoading(true);
            const data = {
                baseurl: values.baseURL.trim(),
                consumer_key: values.consumerKey.trim(),
                consumer_secret: values.consumerSecret.trim(),
            };
            const response = await connectionService.wooCommerceAuth(data);
            if (response.result?.success) {
                toastText('Connected to Woo Commerce successfully', 'success');
            }
            handleCancel();
        } catch (error: any) {
            toastText(error.message, 'error');
        } finally {
            setIsWooConnectionLoading(false);
        }
    };
    const handleCancel = () => {
        onClose();
    };
    return (
        <Modal
            open={authModalVisible}
            onOk={handleSubmit}
            onCancel={handleCancel}
            okText={'Connect'}
            footer={null}
            closable={false}
            className="apiKey_popup"
        >
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                onFinish={handleSubmit}
                autoComplete="off"
            >
                <div className="center">
                    <Image
                        className={styles['image_woocommerce']}
                        src="../wooCommerce-logo.png"
                        preview={false}
                    />
                </div>
                <div>
                    <Row gutter={24}>
                        {WooCommerceFields?.map(
                            (item: IWooFields, index: number) => (
                                <Col
                                    className="gutter-row fields"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    key={index}
                                    lg={24}
                                >
                                    {
                                        <>
                                            <label
                                                className={
                                                    styles['profile-form-label']
                                                }
                                            >
                                                {item?.title}{' '}
                                                {item?.required && (
                                                    <span className="required-color">
                                                        *
                                                    </span>
                                                )}
                                            </label>
                                            <Form.Item
                                                className="formItem"
                                                name={item?.name}
                                                rules={item?.rules as []}
                                                wrapperCol={{ span: 24 }}
                                                validateFirst={true}
                                            >
                                                <Input
                                                    size="large"
                                                    disabled={item?.disabled}
                                                    type={item?.type}
                                                />
                                            </Form.Item>
                                        </>
                                    }
                                </Col>
                            )
                        )}
                    </Row>
                </div>
                <div className={styles['modal_footer']}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        loading={isWooConnectionLoading}
                    >
                        Connect
                    </Button>
                    <Button
                        size="large"
                        htmlType="button"
                        onClick={() => handleCancel()}
                    >
                        Cancel
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};
export default WooCommerceAuthModal;
