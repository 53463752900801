import { Col, Form, Input, Modal, Row, Image, Button } from 'antd';
import { useState } from 'react';
import { toastText } from '../toast';
import connectionService from '../../../client-lib/api/connection-service';
import { APIKey } from '../../../lib/constants/data';
import { IAPIKey } from './types';
import styles from './index.module.css';

type Props = {
    keyModalVisible: boolean;
    onClose: () => void;
};
const APIKeyModal = ({ keyModalVisible, onClose }: Props) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (values: any): Promise<void> => {
        try {
            setIsLoading(true);
            const data = {
                hapikey: values.apiKey.trim(),
            };
            const response = await connectionService.hubSpotAuth(data);
            if (response.result?.success) {
                toastText('Connected successfully', 'success');
            }
            handleCancel();
        } catch (error: any) {
            toastText(error.message, 'error');
        } finally {
            setIsLoading(false);
        }
    };
    const handleCancel = () => {
        onClose();
    };
    return (
        <Modal
            open={keyModalVisible}
            onOk={handleSubmit}
            onCancel={handleCancel}
            okText={'Connect'}
            footer={null}
            closable={false}
            className="apiKey_popup"
        >
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                onFinish={handleSubmit}
                autoComplete="off"
            >
                <div className="center">
                    <Image
                        className="image_hubspot"
                        src="../hubspot.png"
                        preview={false}
                    />
                </div>
                <div>
                    <Row gutter={24}>
                        {APIKey?.map((item: IAPIKey, index: number) => (
                            <Col
                                className="gutter-row fields"
                                xs={24}
                                sm={24}
                                md={24}
                                key={index}
                                lg={24}
                            >
                                {
                                    <>
                                        <label
                                            className={
                                                styles['profile-form-label']
                                            }
                                        >
                                            {item?.title}{' '}
                                            {item?.required && (
                                                <span className="required-color">
                                                    *
                                                </span>
                                            )}
                                        </label>
                                        <Form.Item
                                            className="formItem"
                                            name={item?.name}
                                            rules={item?.rules as []}
                                            wrapperCol={{ span: 24 }}
                                        >
                                            <Input
                                                maxLength={150}
                                                size="large"
                                                disabled={item?.disabled}
                                                type={item?.type}
                                            />
                                        </Form.Item>
                                    </>
                                }
                            </Col>
                        ))}
                    </Row>
                </div>
                <div className={styles['modal_footer']}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        loading={isLoading}
                    >
                        Connect
                    </Button>
                    <Button
                        size="large"
                        htmlType="button"
                        onClick={() => handleCancel()}
                    >
                        Cancel
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};
export default APIKeyModal;
