import { FieldTimeOutlined } from '@ant-design/icons';
import { Col, Popover, RadioChangeEvent, Row, Table } from 'antd';
import { useEffect, useState } from 'react';
import synclogService from '../../../../client-lib/api/synclog-service';
import { CustomPagination } from '../../../../components/global/table-pagination';
import { toastText } from '../../../../components/global/toast';
import { IPagination, IQuery, Page } from '../../../../lib/types/global-types';
import styles from './index.module.css';
import { io } from 'socket.io-client';
import dayjs, { Dayjs } from 'dayjs';
import { hasText } from '../../../../lib/utils';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import PopConfirmSyncInfo from './pop-confirm-info';
import { TableActionBar } from './action-bar';
import { SynclogHistoryModal } from './syncHistory-modal';
import { ISynclog } from './types';
import { LinnworksSyncModal } from './linnworksSync-modal';
import PaymentGateway from '../../../Configurations/PaymentGateway';
import { Status } from '../../../../lib/constants/enum';

export const B2COrders = () => {
    const [loading, setLoading] = useState(true);

    const [openSyncHistoryModal, setOpenSyncHistoryModal] = useState(false);
    const [openLinnworksModal, setOpenLinnworksModal] = useState(false);
    const [linnworkModalData, setLinnworkModalData] = useState({
        date: '',
        paymentGateway: '',
    });

    const [pagination, setPagination] = useState<IPagination>({
        pageNo: 1,
        pageSize: 10,
    });
    const [page, setPage] = useState<Page<any>>({
        content: [],
        count: 0,
    });

    const [syncHistory, setSyncHistory] = useState([]);
    const [fromDate, setFromDate] = useState<Dayjs | null>(null);
    const [toDate, setToDate] = useState<Dayjs | null>(null);
    const [paymentMethod, setPaymentMethod] = useState('all');
    const [sort, setSort] = useState({
        sortBy: 'createdOrderDate',
        sortOrder: 'desc',
    });
    const onSortChange = (e: any) => {
        const sort = {
            sortBy: e.target.name,
            sortOrder: e.target.value,
        };
        setSort(sort);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [socket, setSocket] = useState<any>(null);

    useEffect(() => {
        const token = localStorage.getItem('token'); // Replace with your JWT token
        const socket = io(process.env.REACT_APP_WEB_SOCKET_URL as string, {
            query: { token },
            path: '/api/web-socket',
        });

        socket.on('connect', () => {
            console.log('Connected to Socket.IO server');
        });

        socket.on('synced-with-success-hubspot', (message) => {
            console.log(message);
            getSynclogs(false);
        });

        socket.on('disconnect', () => {
            console.log('Disconnected from Socket.IO server');
        });

        setSocket(socket);

        getSynclogs();

        return () => {
            socket.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        getSynclogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination]);

    const handleOk = () => {
        setPagination({
            pageNo: 1,
            pageSize: 10,
        });
    };

    const getSynclogs = async (showLoader: boolean = true) => {
        if (showLoader) {
            setLoading(true);
        }
        try {
            const query: IQuery = {
                ...pagination,
            };

            sort.sortBy && (query.sortBy = sort.sortBy);
            sort.sortOrder && (query.sortingOrder = sort.sortOrder);

            if (fromDate && toDate) {
                query.fromDate = fromDate.format('YYYY-MM-DD');
                query.toDate = toDate.format('YYYY-MM-DD');
            }

            if (paymentMethod && paymentMethod !== 'all') {
                query.paymentGateway = paymentMethod;
            }

            const res = await synclogService.getB2CSynclogs(query);

            if (res.result) {
                setPage(res.result);
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        }
        setLoading(false);
    };

    const handleSyncShopifyOrder = async () => {
        setLoading(true);

        try {
            const res = await synclogService.syncShopifyOrder();

            if (res.result?.success) {
                toastText('Orders syncing started successfully', 'success');
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        }
        setLoading(false);
    };

    const handleSyncInvoice = async () => {
        setLoading(true);

        try {
            const res = await synclogService.syncInvoice();

            if (res.result?.success) {
                toastText('Invoices syncing started successfully', 'success');
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        }
        setLoading(false);
    };

    const handleSyncPayment = async () => {
        setLoading(true);

        try {
            const res = await synclogService.syncPayment();

            if (res.result?.success) {
                toastText('Payment syncing started successfully', 'success');
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        }
        setLoading(false);
    };

    const handleSyncOrderTracking = async () => {
        setLoading(true);

        try {
            const res = await synclogService.syncOrderTracking();

            if (res.result?.success) {
                toastText(
                    'Order tracking syncing started successfully',
                    'success'
                );
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        }
        setLoading(false);
    };

    const handleSyncStock = async () => {
        setLoading(true);

        try {
            const res = await synclogService.syncStock();

            if (res.result?.success) {
                toastText('Stock syncing started successfully', 'success');
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        }
        setLoading(false);
    };

    const getHubSpotStyle = (record: string) => {
        if (record === Status.COMPLETED) {
            return styles['synced'];
        }

        if (record === Status.PENDING) {
            return styles['pending'];
        }

        return styles['unsynced'];
    };

    const getLinnworksStyle = (record: string) => {
        if (record === Status.COMPLETED) {
            return styles['synced'];
        }

        if (record === Status.PENDING) {
            return styles['pending'];
        }

        return styles['unsynced'];
    };

    const getXeroStyle = (record: string) => {
        if (record === Status.COMPLETED) {
            return styles['synced'];
        }

        if (record === Status.PENDING) {
            return styles['pending'];
        }

        return styles['unsynced'];
    };

    const radioOnChange = (e: RadioChangeEvent) => {
        setPaymentMethod(e.target.value);
    };

    return (
        <div>
            <Row gutter={24}>
                <Col span={24} style={{ marginBottom: '10px' }}>
                    <TableActionBar
                        fromDate={fromDate}
                        toDate={toDate}
                        onApply={handleOk}
                        handleSyncShopifyOrder={handleSyncShopifyOrder}
                        onSyncInvoice={handleSyncInvoice}
                        onSyncPayment={handleSyncPayment}
                        onChangeFromDate={(date) => setFromDate(date)}
                        onChangeToDate={(date) => setToDate(date)}
                        paymentGatewayOnChange={radioOnChange}
                        paymentGatewayMethod={paymentMethod}
                        onSyncOrderTracking={handleSyncOrderTracking}
                        onSyncStock={handleSyncStock}
                        onSortChange={onSortChange}
                    />
                </Col>
                <Col
                    span={24}
                    style={{
                        height: 'calc(100vh - 249.48px)',
                    }}
                >
                    <Table
                        pagination={false}
                        loading={loading}
                        dataSource={page.content}
                        scroll={{
                            y: 'calc(100vh - 330px)',
                        }}
                        rowKey={(data: ISynclog) => {
                            return data.id;
                        }}
                        columns={[
                            {
                                title: 'Date',
                                dataIndex: 'createdOrderDate',
                                key: 'createdOrderDate',
                                width: 120,
                                render: (value: string) => {
                                    return hasText(value)
                                        ? moment(value).format('MM/DD/YYYY')
                                        : '-';
                                },
                            },
                            {
                                title: 'Payment Gateway',
                                dataIndex: 'paymentGateway',
                                defaultSortOrder: 'ascend',
                                // sorter: (a, b) => a.paymentGateway.localeCompdare(b.paymentGateway),
                                key: 'paymentGateway',
                                render: (value: string) => {
                                    return hasText(value) ? value : '-';
                                },
                            },
                            {
                                title: 'Sales',
                                dataIndex: 'sales',
                                key: 'sales',
                                render: (value: string) => {
                                    return hasText(value) ? (
                                        <NumericFormat
                                            value={value}
                                            thousandSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            displayType="text"
                                            prefix="£"
                                            renderText={(value) => (
                                                <p>{value ?? 0}</p>
                                            )}
                                        />
                                    ) : (
                                        '-'
                                    );
                                },
                            },
                            {
                                title: 'Discounts',
                                dataIndex: 'discounts',
                                key: 'discounts',
                                render: (value: string) => {
                                    return hasText(value) ? (
                                        <NumericFormat
                                            value={value}
                                            thousandSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            displayType="text"
                                            prefix="£"
                                            renderText={(value) => (
                                                <p>{value ?? 0}</p>
                                            )}
                                        />
                                    ) : (
                                        '-'
                                    );
                                },
                            },
                            {
                                title: 'Tax',
                                dataIndex: 'tax',
                                key: 'tax',
                                render: (value: string) => {
                                    return hasText(value) ? (
                                        <NumericFormat
                                            value={value}
                                            thousandSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            displayType="text"
                                            prefix="£"
                                            renderText={(value) => (
                                                <p>{value ?? 0}</p>
                                            )}
                                        />
                                    ) : (
                                        '-'
                                    );
                                },
                            },
                            // {
                            //     title: 'Refund',
                            //     dataIndex: 'refund',
                            //     key: 'refund',
                            //     render: (value: string) => {
                            //         return hasText(value) ? (
                            //             <NumericFormat
                            //                 value={value}
                            //                 thousandSeparator=","
                            //                 decimalScale={2}
                            //                 fixedDecimalScale={true}
                            //                 displayType="text"
                            //                 prefix="£"
                            //                 renderText={(value) => (
                            //                     <p>{value ?? 0}</p>
                            //                 )}
                            //             />
                            //         ) : (
                            //             '-'
                            //         );
                            //     },
                            // },
                            {
                                title: 'Gateway Transaction',
                                dataIndex: 'gatewayTransaction',
                                key: 'gatewayTransaction',
                                width: 120,
                                render: (value: string) => {
                                    return hasText(value) ? (
                                        <NumericFormat
                                            value={value}
                                            thousandSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            displayType="text"
                                            prefix="£"
                                            renderText={(value) => (
                                                <p>{value ?? 0}</p>
                                            )}
                                        />
                                    ) : (
                                        '-'
                                    );
                                },
                            },
                            {
                                title: 'Payout',
                                dataIndex: 'payout',
                                key: 'payout',
                                render: (value: string) => {
                                    return hasText(value) ? (
                                        <NumericFormat
                                            value={value}
                                            thousandSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            displayType="text"
                                            prefix="£"
                                            renderText={(value) => (
                                                <p>{value ?? 0}</p>
                                            )}
                                        />
                                    ) : (
                                        '-'
                                    );
                                },
                            },
                            {
                                title: '',
                                dataIndex: 'synclogStatus',
                                key: 'synclogStatus',
                                width: 450,
                                render: (_, record: ISynclog) => {
                                    return (
                                        <div className={styles['sync-status']}>
                                            <Popover
                                                content={
                                                    <PopConfirmSyncInfo
                                                        title="hubspot"
                                                        invoiceData={record}
                                                    />
                                                }
                                                title={false}
                                            >
                                                <div
                                                    className={`${styles['arrow']
                                                        } ${getHubSpotStyle(
                                                            record.hubspotStatus
                                                        )}`}
                                                >
                                                    Hubspot
                                                </div>
                                            </Popover>

                                            <div
                                                onMouseUp={() => {
                                                    const createdOrderDate =
                                                        record.createdOrderDate;
                                                    setLinnworkModalData({
                                                        date: createdOrderDate,
                                                        paymentGateway:
                                                            record.paymentGateway,
                                                    });
                                                    setOpenLinnworksModal(true);
                                                }}
                                                className={`${styles['arrow']
                                                    } ${getLinnworksStyle(
                                                        record.linnworksStatus
                                                    )}`}
                                            >
                                                Linnworks
                                            </div>

                                            <Popover
                                                content={
                                                    <PopConfirmSyncInfo
                                                        title="xero"
                                                        invoiceData={record}
                                                    />
                                                }
                                                title={false}
                                            >
                                                <div
                                                    className={`${styles['arrow']
                                                        } ${getXeroStyle(
                                                            record.xeroStatus
                                                        )}`}
                                                >
                                                    Xero
                                                </div>
                                            </Popover>
                                        </div>
                                    );
                                },
                            },
                            {
                                title: '',
                                dataIndex: 'syncHistory',
                                key: 'syncHistory',
                                width: 150,
                                render: (_, record: any) => {
                                    return (
                                        <div
                                            className={`${record.syncHistory
                                                ? styles['syncHistory']
                                                : styles['notSyncHistory']
                                                }`}
                                            onClick={() => {
                                                if (record.syncHistory) {
                                                    setOpenSyncHistoryModal(
                                                        true
                                                    );
                                                    setSyncHistory(
                                                        record.syncHistory
                                                    );
                                                }
                                            }}
                                        >
                                            <FieldTimeOutlined
                                                style={{ fontSize: '18px' }}
                                            />
                                            <p>Sync History</p>
                                        </div>
                                    );
                                },
                            },
                        ]}
                    />
                </Col>
                {page.count ? (
                    <Col span={24} style={{ marginTop: '10px' }}>
                        <CustomPagination
                            pagination={pagination}
                            counts={page.count}
                            onChangePagination={(data: IPagination) => {
                                setPagination({ ...data });
                            }}
                        />
                    </Col>
                ) : null}
            </Row>
            {openSyncHistoryModal ? (
                <SynclogHistoryModal
                    open={openSyncHistoryModal}
                    syncHistory={syncHistory}
                    onCancel={() => {
                        setOpenSyncHistoryModal(false);
                    }}
                />
            ) : null}
            {openLinnworksModal ? (
                <LinnworksSyncModal
                    open={openLinnworksModal}
                    date={linnworkModalData.date}
                    paymentGateway={linnworkModalData.paymentGateway}
                    onCancel={() => {
                        setOpenLinnworksModal(false);
                    }}
                />
            ) : null}
        </div>
    );
};
