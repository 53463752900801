import { Button, Col, Input, Row } from 'antd';
import styles from './index.module.css';
import { useState } from 'react';
import { fullTrim, hasText } from '../../lib/utils';
import authService from '../../client-lib/api/auth-service';
import { Link, useNavigate } from 'react-router-dom';
import { toastText } from '../../components/global/toast';

export const ForgotPassword = () => {
    const [email, setEmail] = useState<string>('');
    const [showError, setShowError] = useState(false);

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!hasText(email)) {
            setShowError(true);
            return;
        }

        setLoading(true);
        try {
            const res = await authService.forgotPasswordLink({
                email,
            });

            if (res?.result?.success) {
                navigate('/login');
                toastText(
                    'Reset password link has been sent to your email',
                    'success'
                );
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        }
        setLoading(false);
    };

    return (
        <div className={styles['login-container']}>
            <div className={styles['login-img']}>
                <img
                    src="/crystal-clear-logo.webp"
                    alt="Crystal Clear"
                    height={55}
                />
            </div>
            <form onSubmit={handleSubmit}>
                <Row gutter={24}>
                    <Col
                        span={24}
                        className="mt-20"
                        style={{ textAlign: 'center' }}
                    >
                        <p>
                            Enter your registered email below for reset password
                        </p>
                    </Col>
                    <Col span={24} className="mt-20">
                        <Input
                            status={showError && !hasText(email) ? 'error' : ''}
                            placeholder="Email"
                            style={{
                                width: '100%',
                            }}
                            size="large"
                            type="email"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            onBlur={(e) => {
                                setEmail(fullTrim(e.target.value));
                            }}
                        />
                        {showError && !hasText(email) ? (
                            <p style={{ color: '#ff4d4f' }}>
                                Please input your email
                            </p>
                        ) : null}
                    </Col>
                    <Col span={24} className="mt-20">
                        <Link to={'/login'} style={{ textAlign: 'right' }}>
                            <p>Back To Login</p>
                        </Link>
                    </Col>
                    <Col span={24} className="mt-20">
                        <Button
                            htmlType="submit"
                            size="large"
                            style={{
                                width: '100%',
                            }}
                            type="primary"
                            loading={loading}
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </div>
    );
};
