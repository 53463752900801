import { Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PaymentGateway from './PaymentGateway';
import Products from './Products';
import styles from './index.module.css';

export const Configurations = () => {
    const [searchParams, setSearchparams] = useSearchParams();

    const [activeTab, setActiveTab] = useState(
        searchParams.get('activeTab') || 'products'
    );

    const items = [
        {
            key: 'products',
            label: 'Products',
        },
        {
            key: 'paymentGateway',
            label: 'Payment Gateway',
        },
    ];

    useEffect(() => {
        setActiveTab(searchParams.get('activeTab') || 'products');
    }, [searchParams]);

    return (
        <div className={styles.container}>
            <Tabs
                activeKey={activeTab}
                items={items}
                onChange={(key) => {
                    setSearchparams({
                        activeTab: key,
                    });
                }}
                size="large"
            />

            <div className={styles.mainContainer}>
                {activeTab === 'products' && <Products />}
                {activeTab === 'paymentGateway' && <PaymentGateway />}
            </div>
        </div>
    );
};

