import { Button, Col, Modal, Row, Select } from 'antd';
import { IUser } from './types';
import { IOptions } from '../../../../lib/types/global-types';
import Input from 'antd/es/input/Input';
import { useEffect, useState } from 'react';
import { toastText } from '../../../../components/global/toast';
import userService from '../../../../client-lib/api/user-service';
import { fullTrim, hasText } from '../../../../lib/utils';

type Props = {
    open: boolean;
    onCancel: (isEdit?: boolean) => void;
    userData: IUser | null;
    roles: IOptions[];
};

export const SaveUserModal = ({ open, onCancel, userData, roles }: Props) => {
    useEffect(() => {
        if (userData) {
            setName(userData.name);
            setRoleId(userData.roleId);
            setEmail(userData.email);
        }
    }, [userData]);

    const [name, setName] = useState('');
    const [roleId, setRoleId] = useState<string | null>(null);
    const [email, setEmail] = useState('');

    const [loading, setLoading] = useState(false);

    const [hasError, setHasError] = useState(false);

    const handleSubmit = async () => {
        try {
            if (!hasText(name) || !hasText(roleId) || !hasText(email)) {
                setHasError(true);
                return;
            }

            setLoading(true);

            if (!userData) {
                const res = await userService.inviteUser({
                    name,
                    email,
                    roleId: roleId as string,
                });

                if (res.result?.success) {
                    toastText('User invited successfully', 'success');
                }
            } else {
                const res = await userService.updateUser(userData.id, {
                    name,
                    roleId: roleId as string,
                });

                if (res.result?.success) {
                    toastText('User updated successfully', 'success');
                }
            }

            onCancel(true);
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        }

        setLoading(false);
    };

    return (
        <Modal
            open={open}
            footer={null}
            width={600}
            onCancel={() => onCancel(false)}
            title={userData ? 'Edit User' : 'Invite User'}
        >
            <div>
                <form
                    style={{ paddingTop: '20px' }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                >
                    <Row gutter={24}>
                        <Col span={12} style={{ marginBottom: '10px' }}>
                            <Input
                                id="name"
                                placeholder="Name"
                                value={name}
                                size="large"
                                status={
                                    hasError && !hasText(name) ? 'error' : ''
                                }
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    if (/^[a-zA-Z\s]*$/.test(newValue)) {
                                        setName(newValue);
                                    }
                                }}
                                style={{
                                    width: '100%',
                                }}
                                onBlur={(e) =>
                                    setName(fullTrim(e.target.value))
                                }
                            />
                            {hasError && !hasText(name) ? (
                                <p style={{ color: '#ff4d4f' }}>
                                    Please enter your name
                                </p>
                            ) : null}
                        </Col>
                        <Col span={12} style={{ marginBottom: '10px' }}>
                            <Select
                                id="role"
                                placeholder="Select Role"
                                value={roleId}
                                options={roles}
                                size="large"
                                onChange={(e) => setRoleId(e)}
                                status={
                                    hasError && !hasText(roleId) ? 'error' : ''
                                }
                                style={{
                                    width: '100%',
                                }}
                            />
                            {hasError && !hasText(roleId) ? (
                                <p style={{ color: '#ff4d4f' }}>
                                    Please select role
                                </p>
                            ) : null}
                        </Col>
                        <Col span={24} style={{ marginBottom: '20px' }}>
                            <Input
                                id="email"
                                placeholder="Email"
                                value={email}
                                size="large"
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                                status={
                                    hasError && !hasText(email) ? 'error' : ''
                                }
                                disabled={userData ? true : false}
                                style={{
                                    width: '100%',
                                }}
                                onBlur={(e) =>
                                    setEmail(fullTrim(e.target.value))
                                }
                            />
                            {hasError && !hasText(email) ? (
                                <p style={{ color: '#ff4d4f' }}>
                                    Please enter a valid email address
                                </p>
                            ) : null}
                        </Col>
                        <Col span={24}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    loading={loading}
                                >
                                    {userData ? 'Edit' : 'Invite'} User
                                </Button>
                                <Button
                                    size="large"
                                    htmlType="button"
                                    style={{
                                        marginLeft: '10px',
                                    }}
                                    onClick={() => onCancel()}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </form>
            </div>
        </Modal>
    );
};
