import React, { useEffect, useState } from 'react';
import { hasRoleFromToken, isExpired } from '../../client-lib/jwt-service';
import { antStringToRegex } from '../../client-lib/regex-util';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ROLE_NAME } from '../../lib/constants/data';
import { PageLayout } from '../layout/page-layout';

const authorizedPaths = [
    {
        path: antStringToRegex('/user-roles**'),
        userRoles: [ROLE_NAME.SUPER_ADMIN]
    },
    {
        path: antStringToRegex('/configuration**'),
        userRoles: [ROLE_NAME.SUPER_ADMIN, ROLE_NAME.ACCOUNT_HEADS]
    },
    {
        path: antStringToRegex('/integrations**'),
        userRoles: [ROLE_NAME.SUPER_ADMIN]
    },
    {
        path: antStringToRegex('/sync-logs**'),
        userRoles: [ROLE_NAME.SUPER_ADMIN, ROLE_NAME.ACCOUNT_HEADS, ROLE_NAME.INVENTORY_MANAGER, ROLE_NAME.SALES_REP]
    },
    {
        path: antStringToRegex('/sales-report**'),
        userRoles: [ROLE_NAME.SUPER_ADMIN, ROLE_NAME.ACCOUNT_HEADS, ROLE_NAME.SALES_REP]
    },
    {
        path: antStringToRegex('/**'),
    }
];

const unAuthorizedPaths = [
    '/login',
    '/forgot-password',
    '/reset-password',
];

export default function SecurityConfiguration() {
    const navigate = useNavigate();
    const [isAuthorized, setAuthorized] = useState(false);
    const location = useLocation();
    const authorizedPath = authorizedPaths.find(authPath => new RegExp(authPath.path).test(location.pathname));
    const isOnAuthorizedPath = authorizedPath && !unAuthorizedPaths.some(authPath => new RegExp(authPath).test(location.pathname));

    useEffect(() => {
        localStorage.setItem('isOnAuthorizedPath', !!isOnAuthorizedPath ? 'true' : 'false');
        if (isOnAuthorizedPath) {
            const token = localStorage.getItem('token');

            let _isAuthorized = false;

            if (token) {
                _isAuthorized = !isExpired(token);
                if (authorizedPath.userRoles) {
                    _isAuthorized = hasRoleFromToken(token, authorizedPath.userRoles);
                } else {
                    _isAuthorized = !isExpired(token);
                }
            }

            if (_isAuthorized) {
                setAuthorized(_isAuthorized);
            } else {
                navigate(`/login?next=${encodeURIComponent(location.pathname)}`);
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOnAuthorizedPath, location, isAuthorized, authorizedPath]);

    if (!isOnAuthorizedPath || isAuthorized) {
        return (
            <>
                {
                    isOnAuthorizedPath ? (
                        <PageLayout />
                    ) : (
                        <Outlet />
                    )
                }
            </>
        );
    }

    return (<span>Authenticating...</span>);
}
