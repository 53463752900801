import { FC, useEffect, useState } from 'react';
import { UserNameBoxProps } from './types';
import styles from './index.module.css';
import { Dropdown, MenuProps } from 'antd';
import { EditOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { toastText } from '../toast';
import { useNavigate } from 'react-router-dom';
import authService from '../../../client-lib/api/auth-service';
import { EditProfileModal } from './edit-profile-modal';

const UserNameBox: FC<UserNameBoxProps> = (props) => {
    const { fetchProfile, userData } = props;

    const nameArr = userData?.name.split(' ');
    const newName = nameArr
        ? (nameArr[0]?.charAt(0) || '') + (nameArr[1]?.charAt(0) || '')
        : '';
    const navigate = useNavigate();

    const [openEditProfileModal, setOpenEditProfileModal] = useState(false);
    const logoutHandler = async () => {
        try {
            await authService.logout({});
            toastText('Logout successfully', 'success');
            navigate('/login');
        } catch (error: any) {
            toastText('Something went wrong', 'error');
            localStorage.clear();
        } finally {
            localStorage.removeItem('token');
        }
    };

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div className={styles['profile-menu']}>
                    <UserOutlined />
                    <p>{userData?.name}</p>
                </div>
            ),
        },
        {
            key: '2',
            label: (
                <div
                    className={styles['profile-menu']}
                    onClick={() => {
                        setOpenEditProfileModal(true);
                    }}
                >
                    <EditOutlined />
                    <p>Edit Profile</p>
                </div>
            ),
        },
        {
            key: '3',
            label: (
                <div className={styles['profile-menu']} onClick={logoutHandler}>
                    <LogoutOutlined />
                    <p>Logout</p>
                </div>
            ),
        },
    ];

    return (
        <div>
            <Dropdown menu={{ items }} placement="bottomLeft">
                <div className={styles['user-name-box']}>
                    <p className={styles['name']}>{newName}</p>
                </div>
            </Dropdown>
            {openEditProfileModal ? (
                <EditProfileModal
                    open={openEditProfileModal}
                    onCancel={() => {
                        setOpenEditProfileModal(false);
                    }}
                    userData={userData}
                    fetchProfile={fetchProfile}
                />
            ) : null}
        </div>
    );
};

export default UserNameBox;
