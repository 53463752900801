import http from '../http';

const BASE_PATH = process.env.REACT_APP_API_ENDPOINT;

const login = async (data: any) => {
    return http.post(`${BASE_PATH}/v1/auth/login`, {
        body: data,
    });
};

const changePassword = async (data: any) => {
    return http.post(`${BASE_PATH}/v1/auth/change-password`, {
        body: data,
    });
};

const forgotPasswordLink = async (data: any) => {
    return http.post(`${BASE_PATH}/v1/auth/forgot-password`, {
        body: data,
    });
};

const resetPassword = async (data: any) => {
    return http.post(`${BASE_PATH}/v1/auth/reset-password`, {
        body: data,
    });
};

const logout = async (data: any) => {
    return http.post(`${BASE_PATH}/v1/auth/logout`, {
        body: data,
    });
};

const fetchProfile = async () => {
    return http.get(`${BASE_PATH}/v1/auth/fetch-profile`);
};

const editProfile = async (data: any) => {
    return http.put(`${BASE_PATH}/v1/auth/profile`, { body: data });
};

const authService = {
    login,
    resetPassword,
    changePassword,
    forgotPasswordLink,
    logout,
    fetchProfile,
    editProfile,
};

export default authService;
