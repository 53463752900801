import { Button, DatePicker, Menu, Modal, Popconfirm, Radio } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import styles from './index.module.css';
import { useState } from 'react';
import { FilterOutlined, SyncOutlined } from '@ant-design/icons';
import GlobalDatePicker from '../../../Configurations/Products/globalDatePicker';
import { COGSSynclogs } from '../COGSSynclogs';

type Props = {
    fromDate: Dayjs | null;
    toDate: Dayjs | null;
    onApply: () => void;
    onSyncNow: () => void;
    onSyncInvoice: () => void;
    onSyncPayment: () => void;
    onChangeFromDate: (date: Dayjs | null) => void;
    onChangeToDate: (date: Dayjs | null) => void;
    invoiceStatus: string | null;
    invoiceStatusOnChange: any;
    onSyncOrderTracking: () => void;
    onSyncStock: () => void;
};

export const TableActionBar = ({
    fromDate,
    toDate,
    onApply,
    onSyncNow,
    onSyncInvoice,
    onChangeFromDate,
    onChangeToDate,
    onSyncPayment,
    invoiceStatus,
    invoiceStatusOnChange,
    onSyncOrderTracking,
    onSyncStock,
}: Props) => {
    const [selectedKey, setSelectedKey] = useState('date');

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
    };
    const items = [
        {
            key: 'date',
            label: 'Date',
        },
        // {
        //     key: 'customer',
        //     label: 'Customer',
        // },
        // {
        //     key: 'syncStatus',
        //     label: 'Sync Status',
        // },
        // {
        //     key: 'amount',
        //     label: 'Amount',
        // },
        {
            key: 'invoiceStatus',
            label: 'Invoice Status',
        },
    ];

    const handleMenuClick = (e: any) => {
        setSelectedKey(e.key);
    };

    const handleCancel = () => {
        setSelectedKey('date');
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <div style={{ display: 'flex', gap: '10px' }}>

                <Popconfirm
                    placement="rightBottom"
                    title={false}
                    description={
                        <div className={styles['filer-content']}>
                            <Menu
                                mode="vertical"
                                items={items}
                                onClick={handleMenuClick}
                                selectedKeys={[selectedKey]}
                                defaultSelectedKeys={[selectedKey]}
                            />
                            {selectedKey === 'date' && (
                                <div>
                                    <GlobalDatePicker
                                        label="From Date"
                                        value={fromDate}
                                        onChange={onChangeFromDate}
                                        disabledDate={(current) => {
                                            const flag = toDate && current && current > dayjs(toDate);
                                            return Boolean(flag);
                                        }}
                                    />

                                    <GlobalDatePicker
                                        label="To Date"
                                        value={toDate}
                                        onChange={onChangeToDate}
                                        disabledDate={(current) => {
                                            const flag = fromDate && current && current < dayjs(fromDate);
                                            return Boolean(flag);
                                        }}
                                    />
                                </div>
                            )}
                            {selectedKey === 'customer' && (
                                <div>
                                    <p>Customer information</p>
                                </div>
                            )}
                            {selectedKey === 'syncStatus' && (
                                <div>
                                    <p>syncStatus information</p>
                                </div>
                            )}
                            {selectedKey === 'amount' && (
                                <div>
                                    <p>amount information</p>
                                </div>
                            )}
                            {selectedKey === 'invoiceStatus' && (
                                <div>
                                    <Radio.Group
                                        onChange={invoiceStatusOnChange}
                                        value={invoiceStatus}
                                    >
                                        <Radio value={'all'}>All</Radio>
                                        <Radio value={'paid'}>Paid</Radio>
                                        <Radio value={'open'}>Open</Radio>
                                    </Radio.Group>
                                </div>
                            )}
                        </div>
                    }
                    okText="Apply"
                    cancelText="Cancel"
                    onConfirm={() => onApply && onApply()}
                    onCancel={handleCancel}
                    icon={false}
                >

                    <Button type="primary" size="large" icon={<FilterOutlined />}>
                        Filters
                    </Button>
                </Popconfirm>

                <Button
                    type="primary"
                    size="large"
                    onClick={showModal}
                >
                    COGS Synclogs
                </Button>

                <Modal
                    title="COGS Synclogs"
                    visible={isModalVisible}
                    onCancel={handleModalClose}
                    footer={null}
                    width="90%"
                    style={
                        {
                            top: 60,
                        }
                    }
                >
                    <COGSSynclogs />
                </Modal>
            </div>
            <div style={{ display: 'flex', gap: '10px' }}>
                <Button
                    type="primary"
                    size="large"
                    icon={<SyncOutlined />}
                    onClick={() => {
                        onSyncNow();
                    }}
                >
                    Sync Order
                </Button>
                <Button
                    type="primary"
                    size="large"
                    icon={<SyncOutlined />}
                    onClick={() => {
                        onSyncInvoice();
                    }}
                >
                    Sync Invoices
                </Button>
                <Button
                    type="primary"
                    size="large"
                    icon={<SyncOutlined />}
                    onClick={() => {
                        onSyncPayment();
                    }}
                >
                    Sync Payment
                </Button>
                <Button
                    type="primary"
                    size="large"
                    icon={<SyncOutlined />}
                    onClick={() => {
                        onSyncOrderTracking();
                    }}
                >
                    Sync Order Tracking
                </Button>
                <Button
                    type="primary"
                    size="large"
                    icon={<SyncOutlined />}
                    onClick={() => {
                        onSyncStock();
                    }}
                >
                    Sync Stock
                </Button>
            </div>
        </div>
    );
};
