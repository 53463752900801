import { ConfigProvider } from 'antd';

export const ThemeRegistry = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#6C8289'
                }
            }}
        >
            {children}
        </ConfigProvider>
    )
}