import { IQuery, Page, Result } from '../../lib/types/global-types';
import { IUserInviteData, IUserUpdateData } from '../../lib/types/user-types';
import { IUser } from '../../pages/UserRoles/Components/Users/types';
import http from '../http';

const BASE_PATH = process.env.REACT_APP_API_ENDPOINT;

const getAllUser = async (query: IQuery): Promise<Result<Page<IUser>>> => {
    return http.get(`${BASE_PATH}/v1/users`, {
        queryParams: query,
    });
};

const inviteUser = async (data: IUserInviteData) => {
    return http.post(`${BASE_PATH}/v1/users/invite`, {
        body: data,
    });
};

const updateUser = async (id: string, data: IUserUpdateData) => {
    return http.put(`${BASE_PATH}/v1/users/${id}`, {
        body: data,
    });
};

const updateUserStatus = async (id: string, status: string) => {
    return http.put(`${BASE_PATH}/v1/users/${id}/status/${status}`);
};

const deleteUser = async (id: string) => {
    return http.put(`${BASE_PATH}/v1/users/delete/${id}`, { body: null });
};

const reinviteUser = async (email: string) => {
    return http.post(`${BASE_PATH}/v1/users/reinvite`, {
        body: { email },
    });
};


const userService = {
    getAllUser,
    inviteUser,
    updateUser,
    updateUserStatus,
    deleteUser,
    reinviteUser
};

export default userService;
