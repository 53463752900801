export const ROLE_NAME = {
    SUPER_ADMIN: 'SUPER_ADMIN',
    SALES_REP: 'SALES_REP',
    INVENTORY_MANAGER: 'INVENTORY_MANAGER',
    ACCOUNT_HEADS: 'ACCOUNT_HEADS',
};
export const APIKey = [
    {
        title: 'API Key',
        id: 'apiKey',
        type: 'text',
        name: 'apiKey',
        defaultValue: '',
        disabled: false,
        errorMessage: 'Please enter API key',
        required: true,
        rules: [
            {
                required: true,
                message: 'Please enter API key',
                validateTrigger: 'onChange',
            },
            {
                min: 10,
                message: 'API key length must contain at least 10 characters',
                validateTrigger: 'onChange',
            },
            {
                max: 150,
                message: 'API key length must be less than 150 characters',
                validateTrigger: 'onChange',
            },
        ],
    },
];
export const WooCommerceFields = [
    {
        title: 'Base URL',
        id: 'baseURL',
        type: 'text',
        name: 'baseURL',
        defaultValue: '',
        disabled: false,
        errorMessage: 'Please enter base URL',
        required: true,
        rules: [
            {
                required: true,
                message: 'Please enter base URL',
                validateTrigger: 'onChange',
            },
            {
                max: 250,
                message: 'Base URL length must be less than 250 characters',
                validateTrigger: 'onChange',
            },
            {
                pattern: new RegExp(
                    /^(http|https):\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}/
                ),
                message: 'Please enter a valid URL',
                validateTrigger: 'onChange',
            },
        ],
    },
    {
        title: 'Consumer Key',
        id: 'consumerKey',

        type: 'text',
        name: 'consumerKey',
        defaultValue: '',
        disabled: false,
        errorMessage: 'Please enter consumer key',
        required: true,
        rules: [
            {
                required: true,
                message: 'Please enter consumer key',
                validateTrigger: 'onChange',
            },
            {
                min: 10,
                message: 'Consumer key must contain at least 10 characters',
                validateTrigger: 'onChange',
            },
            {
                max: 250,
                message: 'Consumer key length must be less than 250 characters',
                validateTrigger: 'onChange',
            },
            {
                pattern: new RegExp(/^[a-zA-Z0-9_-]+$/),
                message:
                    'Please enter a valid consumer key, special characters are not allowed',
                validateTrigger: 'onChange',
            },
        ],
    },
    {
        title: 'Consumer Secret',
        id: 'consumerSecret',
        type: 'text',
        name: 'consumerSecret',
        defaultValue: '',
        disabled: false,
        errorMessage: 'Please enter consumer secret',
        required: true,
        rules: [
            {
                required: true,
                message: 'Please enter consumer secret',
                validateTrigger: 'onChange',
            },
            {
                min: 10,
                message: 'Consumer secret must contain at least 10 characters',
                validateTrigger: 'onChange',
            },
            {
                max: 250,
                message:
                    'Consumer secret length must be less than 250 characters',
                validateTrigger: 'onChange',
            },
            {
                pattern: new RegExp(/^[a-zA-Z0-9_-]+$/),
                message:
                    'Please enter a valid consumer secret, special characters are not allowed',
                validateTrigger: 'onChange',
            },
        ],
    },
];
