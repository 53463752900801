import { Pagination, Select } from 'antd';
import { IPagination } from '../../lib/types/global-types';

type Props = {
    counts: number;
    pagination: IPagination;
    onChangePagination: (pagination: IPagination) => void;
};

export const CustomPagination = ({
    counts,
    pagination,
    onChangePagination,
}: Props) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Pagination
                className={''}
                total={counts}
                // showTotal={"showTotal"}
                showSizeChanger={!true}
                onShowSizeChange={(e, pageSize) => {
                    onChangePagination &&
                        onChangePagination({
                            ...pagination,
                            pageSize,
                        });
                }}
                onChange={(e) => {
                    onChangePagination &&
                        onChangePagination({
                            ...pagination,
                            pageNo: e,
                        });
                }}
                current={pagination.pageNo}
                pageSize={pagination.pageSize}
            />
            <Select
                style={{ width: 120 }}
                value={pagination.pageSize}
                onChange={(pageSize: number) => {
                    onChangePagination &&
                        onChangePagination({
                            pageSize,
                            pageNo: 1,
                        });
                }}
                options={[
                    { value: 10, label: '10 / Page' },
                    { value: 25, label: '25 / Page' },
                    { value: 50, label: '50 / Page' },
                    { value: 100, label: '100 / Page' },
                ]}
            />
        </div>
    );
};
