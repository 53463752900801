import { useEffect, useState } from 'react';
import roleService from '../../../../client-lib/api/role-service';
import { toastText } from '../../../../components/global/toast';
import { Switch, Table } from 'antd';
import { Role } from './types';
import { ROLE_NAME } from '../../../../lib/constants/data';

export const RolesPage = () => {
    const [loading, setLoading] = useState(true);
    const [roles, setRoles] = useState<Role[]>([]);

    useEffect(() => {
        getAllRoles();
    }, []);

    const getAllRoles = async () => {
        setLoading(true);
        try {
            const res = await roleService.getAllRoles();

            if (res.result?.length) {
                setRoles(res.result);
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        }
        setLoading(false);
    };

    const updateRoleStatus = async (id: string, status: boolean) => {
        setLoading(true);
        const statusText = status ? 'Active' : 'Inactive';
        try {
            const res = await roleService.updateRoleStatus(id, statusText);

            if (res.result?.success) {
                await getAllRoles();
                toastText(`Role ${statusText} successfully`, 'success');
            }
        } catch (error: any) {
            let errorMessage = error.message;

            if (error.errorDescription) {
                errorMessage = error.errorDescription;
            }

            toastText(errorMessage, 'error');
        }
        setLoading(false);
    };

    return (
        <div>
            <Table
                pagination={false}
                loading={loading}
                dataSource={roles}
                rowKey={(data: Role) => {
                    return data.id;
                }}
                columns={[
                    {
                        title: 'Name',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: 'Description',
                        dataIndex: 'description',
                        key: 'description',
                    },
                    {
                        title: 'Status',
                        dataIndex: 'status',
                        key: 'status',
                        render: (value: boolean, record: Role) => {
                            return record.type !== ROLE_NAME.SUPER_ADMIN ? (
                                <Switch
                                    checked={value}
                                    onChange={(flag: boolean) => {
                                        updateRoleStatus(record.id, flag);
                                    }}
                                />
                            ) : (
                                'Active'
                            );
                        },
                    },
                ]}
            />
        </div>
    );
};
